import { useLogout } from 'Hooks/useLogout';

function Logout(): JSX.Element {
  const { mutateAsync: logout } = useLogout();
  const logoutPayload = {};
  logout(logoutPayload);
  return <></>;
}

export default Logout;
