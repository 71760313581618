import { useApi } from 'Contexts/ApiContext';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type OrderResponse = [order: any, isFetching: boolean] | any[];

export const useGetOrder = (id: string): OrderResponse => {
  const api = useApi();
  const token = localStorage.getItem('token');
  const fetchOrder = async (): Promise<any> => {
    try {
      return (await api.get(`/wp-json/smart-piano-api/v1/orders/${id}`)).data;
    } catch (err) {
      console.log('order error', err);
      return [];
    }
  };

  const placeholderData = useMemo(() => {
    return [];
  }, []);

  const { data: order, isFetching } = useQuery(['order'], fetchOrder, {
    onError: (error) => console.error(error),
    placeholderData,
    enabled: !!token,
  });

  return !order ? [] : [order?.data, isFetching];
};
