import SimpleProducts from 'Components/features/products/SimpleProducts';
import Subscriptions from 'Components/features/products/Subscriptions';

function ProductsList(): JSX.Element {
  return (
    <div className='flex flex-col mb-20'>
      <div className='pb-5 pt-5'>
        <Subscriptions />
      </div>

      <div className='pb-5 pt-5'>
        <SimpleProducts />
      </div>
    </div>
  );
}

export default ProductsList;
