import React from 'react';

type TooltipProps = {
  message: string;
  simple?: boolean;
  warning?: boolean;
};

function Tooltip({ message, simple, warning }: TooltipProps): JSX.Element {
  if (simple) {
    return (
      <div
        className={`opacity-0 w-[250px] mt-[35px] border-[1px] border-gray-400 shadow-lg ${
          warning ? 'bg-amber-50' : 'bg-emerald-50'
        } text-center text-sm rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none`}
      >
        {message}
        <svg
          className='absolute text-black h-2 w-full left-0 top-full'
          x='0px'
          y='0px'
          viewBox='0 0 255 255'
          xmlSpace='preserve'
        >
          <polygon className='fill-current' points='0,0 177.5,177.5 355,0' />
        </svg>
      </div>
    );
  }
  return (
    <div className='opacity-0 w-[250px] bg-primary-500 text-center text-sm font-semibold rounded-lg py-2 absolute z-10 group-hover:opacity-95 bottom-full lg:-left-[47px] ml-14 px-3 pointer-events-none'>
      {message}
      <svg
        className='absolute text-black h-2 w-full left-0 top-full'
        x='0px'
        y='0px'
        viewBox='0 0 255 255'
        xmlSpace='preserve'
      >
        <polygon className='fill-current' points='0,0 127.5,127.5 255,0' />
      </svg>
    </div>
  );
}

export default Tooltip;
