import ProductsList from 'Components/features/products/ProductsList';
import { Category, useGetCategories } from 'Hooks/useGetCategories';
import { useGetCourses } from 'Hooks/useGetCourses';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DotLoader from '../components/common/dot-loader/DotLoader';
import DropdownSelect, {
  DropdownSelectOption,
} from '../components/common/dropdown-select/DropdownSelect';
import Input from '../components/common/input/Input';
import CourseGrid from '../components/features/course-grid/CourseGrid';
import Layout from '../components/ui/layouts/Layout';

function SongLibrary(): JSX.Element {
  const isLoggedIn = localStorage.getItem('token') !== null;
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryPlaceholder = useMemo(
    (): Category => ({
      term_id: -1,
      name: 'All Categories',
      slug: '',
      count: 0,
    }),
    []
  );
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get('page')) || 1
  );
  const searchRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  if (search && searchRef && searchRef.current) {
    searchRef.current.value = search;
  }
  const categorySearch = Number(searchParams.get('category')) || -1;
  const [selectedCategory, setSelectedCategory] = useState(categorySearch);
  function categorySelected(
    categorySelection: DropdownSelectOption,
    initializeCall?: boolean
  ): void {
    if (initializeCall) {
      onPageChange(currentPage, initializeCall);
      // use existing page numbers etc
    } else if (categorySelection.id) {
      setCurrentPage(1);
      setOffset(0);
      setSelectedCategory(categorySelection.id);
      setSearchParams({
        category: categorySelection.id.toString(),
        search: searchRef.current ? searchRef.current.value : '',
        page: '1',
      });
    }
  }

  function executeSearch(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      setCurrentPage(1);
      setOffset(0);
      if (searchRef.current) {
        setSearch(searchRef.current.value);
        setSearchParams({
          category: selectedCategory.toString(),
          search: searchRef.current ? searchRef.current.value : '',
          page: '1',
        });
      }
    }
  }

  const { courseData, isFetching: coursesLoading } = useGetCourses(
    search,
    selectedCategory,
    '',
    limit,
    offset
  );
  const { categories, isFetching: categoriesLoading } = useGetCategories();

  function onPageChange(page: number, initializeCall: boolean): void {
    if (initializeCall) {
      setCurrentPage(page);
      setOffset((page - 1) * limit);
    }
    if (page !== currentPage) {
      setCurrentPage(page);
      setOffset((page - 1) * limit);
      setSearchParams({
        category: selectedCategory.toString(),
        search: searchRef.current ? searchRef.current.value : '',
        page: page.toString(),
      });
    }
  }

  useEffect(() => {
    document.title = 'Song Library - Smart Game Piano';
  }, []);

  return (
    <Layout>
      <div className='px-[15px] md:px-[30px] xl:px-0 flex flex-col min-h-[500px] max-w-[1200px] m-auto pb-[75px] w-auto pt-[15px] xl:pt-[75px]'>
        {isLoggedIn && <ProductsList />}

        <div className='flex flex-row justify-between'>
          <div className='flex px-1.5'>
            <Input
              id='search'
              name='search'
              placeholder='Search Courses'
              inputRef={searchRef}
              onKeyUp={executeSearch}
            />
          </div>
          <div className='w-[200px] pb-[30px] px-1.5'>
            {!categoriesLoading && (
              <DropdownSelect
                initialValue={categorySearch}
                onChange={categorySelected}
                options={[categoryPlaceholder]
                  .concat(categories)
                  .map((category) => ({
                    id: category.term_id,
                    name: category.name,
                  }))}
              />
            )}
          </div>
        </div>

        <div className='flex flex-1 justify-center items-center'>
          {coursesLoading && <DotLoader />}
          {!coursesLoading && (
            <CourseGrid
              courseData={courseData}
              currentPage={currentPage}
              limit={limit}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default SongLibrary;
