import React, { FC } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'Utils/formatMoney';
import 'typeface-poppins';
import 'typeface-roboto';
import { defaultImage } from '../../../shared/helpers/subscription.helpers';

type Product = {
  [key: string]: any;
};

type ItemInfo = {
  item: Product;
};

const OrderItem: FC<ItemInfo> = ({ item }) => {
  return (
    <ProductTable className='border-t-[1px] border-tb-[1px] border-[#E4E4E4] px-0 lg:px-[20px] py-[10px]'>
      <div className='self-center'>
        <Image alt='Course image' src={item?.image || defaultImage} />
      </div>

      <div className='self-center'>
        <div className='font-semibold'>
          # {item?.id} {item?.title}
        </div>
        <div className='ml-0 md:ml-10 text-gray-400'>
          {item?.trialPeriod && (
            <div className='mt-[20px] mb-[5px]'>
              <span className='font-semibold'>Trial period:</span>
              &#160;
              {item.trialPeriod}
            </div>
          )}
          {item?.period && (
            <div className='mb-[25px]'>
              <span className='font-semibold'>Period:</span>
              &#160;
              {item.period}
            </div>
          )}
        </div>
        {item?.sku && (
          <div className='text-gray-400 mt-[15px]'>SKU: {item.sku || '-'}</div>
        )}
      </div>
      <div className='text-gray-500'>{item?.quantity}</div>
      <div className='text-gray-500 font-semibold'>
        {formatMoney(item?.price)}
      </div>
    </ProductTable>
  );
};

const Image = styled.img`
  max-width: 78px;
  max-height: 78px;
  border-radius: 4px;
`;

const ProductTable = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 200px 100px;
  align-items: center;
  @media screen and (max-width: 767px) {
    grid-template-columns: 90px 1fr 1fr 1fr;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 2fr 50px 1fr;
    gap: 22px;
    font-size: 13px;
    div:first-of-type {
      display: none;
    }
    img {
      display: none;
      max-width: 40px;
    }
  }
  & > * {
    text-align: left;
  }
  gap: 30px;
`;

export default OrderItem;
