import { useGetFullUserInfo, useGetUserInfo } from 'Hooks/useGetUserInfo';
import React, { createContext, useEffect, useState } from 'react';
import { Md5 } from 'ts-md5';
import { UserInfo, FullUserInfo } from '../../types/user.info';

type UserInfoContext = {
  isLoggedIn?: boolean;
  setUser?: (value: UserInfo) => void;
  user?: UserInfo;
  fullUser?: FullUserInfo;
};

type UserProviderProps = {
  children: JSX.Element;
};

export const UserContext = createContext<UserInfoContext>({
  isLoggedIn: false,
  setUser: () => void 0,
  user: {
    email: '',
    firstName: '',
    favoriteCourses: [],
    gravatarUrl: '',
    username: '',
  },
  fullUser: null,
});

export function UserProvider({ children }: UserProviderProps): JSX.Element {
  const isLoggedIn = localStorage.getItem('token') !== null;
  const { userInfo } = useGetUserInfo(isLoggedIn);
  const { fullUserInfo } = useGetFullUserInfo(isLoggedIn);

  const [user, setUser] = useState<any>({
    email: userInfo?.email || '',
    firstName: userInfo?.first_name || '',
    favoriteCourses: userInfo?.favoriteCourses || [],
    gravatarUrl:
      `https://www.gravatar.com/avatar/${userInfo?.email}?d=mp` || '',
    username: userInfo?.user_login || '',
  });

  const [fullUser, setFullUser] = useState<any>({
    ...fullUserInfo,
  });

  useEffect(() => {
    if (!userInfo) return;
    const email = userInfo?.email ? userInfo.email : '';
    if (email === '') return;
    const groomedEmail = email.trim().toLowerCase();
    const md5Email = Md5.hashStr(groomedEmail);
    setUser({
      email: userInfo.email,
      firstName: userInfo.first_name,
      favoriteCourses: userInfo.favoriteCourses,
      gravatarUrl: `https://www.gravatar.com/avatar/${md5Email}?d=mp&s=36`,
      username: userInfo.user_login,
    });
    setFullUser({
      ...fullUserInfo,
    });
  }, [userInfo, fullUserInfo]);

  return (
    <UserContext.Provider value={{ isLoggedIn, setUser, user, fullUser }}>
      {children}
    </UserContext.Provider>
  );
}
