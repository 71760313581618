import { useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useQuery } from 'react-query';

type PrivacyData = {
  html: string;
  policy: string;
};

type PrivacyResponse = {
  privacyPolicyData: PrivacyData | undefined;
  isFetching: boolean;
};

export const useGetPrivacyPolicy = (open: boolean): PrivacyResponse => {
  const api = useUnauthenticatedApi();
  const fetchCourses = async (): Promise<PrivacyData> =>
    (await api.get('/api/v1/privacy-policy.php')).data;
  const { data: privacyPolicyData, isFetching } = useQuery(
    ['privacy-policy'],
    fetchCourses,
    {
      enabled: open,
      onError: (error) => console.error(error),
    }
  );
  return { privacyPolicyData, isFetching };
};
