import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  InboxIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import CartList from 'Components/features/cart/CartList';
import { useCartContext } from 'Contexts/CartContext';
import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'typeface-poppins';
import styled from 'styled-components';
import { classNames } from 'Utils/TailwindHelpers';

type HeaderItem = {
  title: string;
  url: string;
};

type UserMenuOption = {
  icon: React.ReactNode;
  link: string;
  name: string;
};

type HeaderProps = {
  gravatarUrl: string | undefined;
  headers: HeaderItem[];
  hideInbox?: boolean;
  hideNotifications?: boolean;
  hideShoppingCart?: boolean;
  userMenuOptions: UserMenuOption[];
};

export default function Header({
  gravatarUrl,
  headers,
  hideInbox = false,
  hideNotifications = false,
  hideShoppingCart = false,
  userMenuOptions,
}: HeaderProps): JSX.Element {
  const apiUrl = process.env.API_URL;
  const cartRef = useRef<HTMLDivElement>(null);
  const { productItems } = useCartContext();

  return (
    <Disclosure
      as='nav'
      className='bg-gradient-to-r from-header-from to-header-to sticky top-0 z-30'
    >
      {({ open }): JSX.Element => (
        <>
          <div className='mx-auto max-w-7xl px-2 lg:px-6 xl:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center lg:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-white  hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center lg:items-stretch lg:justify-start'>
                <div className='flex flex-shrink-0 items-center'>
                  <a href={apiUrl}>
                    <img
                      className='hidden sm:block h-8 w-auto lg:hidden'
                      src='https://d10heettfeutgf.cloudfront.net/uploads/2021/05/logo-100px.png'
                      alt='Smart Game Piano'
                    />
                    <img
                      className='hidden h-8 w-auto lg:block'
                      src='https://d10heettfeutgf.cloudfront.net/uploads/2021/05/logo-100px.png'
                      alt='Smart Game Piano'
                    />
                  </a>
                </div>
                <div className='hidden sm:ml-6 lg:block'>
                  <div className='flex space-x-4'>
                    {headers.map((item) => {
                      return item &&
                        (item.url === '/' ||
                          item.url === '/checkout' ||
                          item.url === '/course-library') ? (
                        <Link
                          to={item.url}
                          key={item.title}
                          className={classNames(
                            item.url === window.location.pathname
                              ? 'text-primary-500'
                              : 'text-white hover:text-primary-500',
                            'px-3 py-2 rounded-md text-md font-medium'
                          )}
                          aria-current={
                            item.url === window.location.pathname
                              ? 'page'
                              : undefined
                          }
                        >
                          {item.title}
                        </Link>
                      ) : (
                        <a
                          key={item.title}
                          href={item.url}
                          className={classNames(
                            item.url === window.location.pathname
                              ? 'text-primary-500'
                              : 'text-white hover:text-primary-500',
                            'px-3 py-2 rounded-md text-md font-medium'
                          )}
                          aria-current={
                            item.url === window.location.pathname
                              ? 'page'
                              : undefined
                          }
                        >
                          {item.title}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto sm:ml-6 sm:pr-0'>
                {!hideInbox && (
                  <button
                    type='button'
                    className='rounded-full p-1 text-white hover:text-primary-500 focus:outline-none'
                  >
                    <span className='sr-only'>View messages</span>
                    <InboxIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                )}
                {!hideNotifications && (
                  <button
                    type='button'
                    className='rounded-full p-1 text-white hover:text-primary-500 focus:outline-none'
                  >
                    <span className='sr-only'>View notifications</span>
                    <BellIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                )}

                {!hideShoppingCart && (
                  <Menu as='div' className='relative p-0 md:p-1'>
                    <div>
                      <Menu.Button className='flex rounded-full text-sm pr-1 text-white hover:text-primary-500 focus:outline-none'>
                        <span className='sr-only'>Stopping cart</span>
                        <div className='relative flex'>
                          <ShoppingCartIcon
                            className='h-6 w-6'
                            aria-hidden='true'
                            onClick={(e) => {
                              if (cartRef.current) {
                                cartRef.current.style.display = 'block';
                              }
                            }}
                          />
                          <span className='absolute top-[-10px] left-[100%]'>
                            {Number(productItems?.length)}
                          </span>
                        </div>
                      </Menu.Button>
                      <div ref={cartRef} className='fixed md:relative right-0'>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='border-3 border-b-emerald-800 bg-emerald-50 min-h-[150px] max-h-[460px] absolute overflow-hidden right-0 z-100 mt-2 w-[100vw] md:w-[550px] origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <CartTitle>
                              <span className='text-green-800'>
                                Shopping cart
                              </span>
                              <XMarkIcon
                                onClick={(e) => {
                                  if (cartRef.current) {
                                    cartRef.current.style.display = 'none';
                                  }
                                }}
                                className='h-6 w-6 font-semibold cursor-pointer text-gray-500 ml-auto'
                              />
                            </CartTitle>
                            <CartList />
                          </Menu.Items>
                        </Transition>
                      </div>
                    </div>
                  </Menu>
                )}

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex rounded-full bg-gray-800 text-sm'>
                      <span className='sr-only'>Open user menu</span>
                      <img
                        className='h-8 w-8 rounded-full'
                        src={gravatarUrl}
                        alt=''
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {userMenuOptions.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }): JSX.Element => (
                            <a
                              href={item.link}
                              className={classNames(
                                active
                                  ? 'bg-menu-hover hover:text-primary-500'
                                  : '',
                                'block px-4 py-2 text-sm text-gray-700 group flex items-center'
                              )}
                            >
                              {item.icon}
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='lg:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {headers.map((item) => (
                <Disclosure.Button
                  key={item.title}
                  as='a'
                  href={item.url}
                  className={classNames(
                    item.url === window.location.pathname
                      ? 'text-primary-500'
                      : 'text-white hover:text-primary-500',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={
                    item.url === window.location.pathname ? 'page' : undefined
                  }
                >
                  {item.title}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

const CartTitle = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
`;
