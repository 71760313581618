import React, { memo } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'Utils/formatMoney';
import { CartProduct } from '../../../types/cart.product';
import OrderItem from './OrderItem';
import 'typeface-poppins';
import 'typeface-roboto';

function OrderList({ order }: any): JSX.Element {
  return (
    <>
      <Title className='text-left'>Order details</Title>

      <List>
        <ProductTable className='px-0 lg:px-[20px] py-[15px]'>
          <div></div>
          <div className='uppercase font-semibold'>Product</div>
          <div className='uppercase font-semibold'>Qty</div>
          <div className='uppercase font-semibold text-gray-500'>Total</div>
        </ProductTable>

        {order?.products && order?.products?.length === 0 && (
          <div
            className={
              'flex h-[150px] items-center pt-[12px] justify-center px-3.5 py-5'
            }
            key={0}
          >
            No items
          </div>
        )}
        {order?.products?.length &&
          order.products.map((item: CartProduct, idx: number) => (
            <OrderItem key={item?.id || idx} item={item} />
          ))}
        <hr />
        <div className='mt-[45px] pr-0 md:pr-[30px]'>
          <div className='flex justify-center sm:justify-end md:justify-center md:gap-[60px] gap-[30px] w-full md:w-1/2 m-auto'>
            <div className='flex flex-col text-gray-500 '>
              <Title $mb={'10px'} className='uppercase w-full border-[#E4E4E4]'>
                Subtotal:
              </Title>
              <Title
                $mb={'10px'}
                className='text-left w-full uppercase color-text-500'
              >
                Total:
              </Title>
            </div>
            <div className='flex flex-col text-gray-500 '>
              <Title $mb={'10px'} className='uppercase w-full border-[#E4E4E4]'>
                {formatMoney(order?.total)}
              </Title>
              <Title $mb={'10px'} className='uppercase w-full text-left '>
                {formatMoney(order?.subtotal)}
              </Title>
            </div>
          </div>
        </div>
      </List>
    </>
  );
}

const List = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;
const Title = styled.div<{ $mb?: string }>`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  color: #000000;
  margin-bottom: ${(props) => `${props.$mb || '24px'}`};
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
`;
const ProductTable = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 200px 100px;
  align-items: center;
  @media screen and (max-width: 767px) {
    grid-template-columns: 90px 1fr 1fr 1fr;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 2fr 50px 1fr;
    gap: 22px;
    font-size: 13px;
    div {
      &:first-of-type {
        display: none;
      }
    }
  }
  & > * {
    text-align: left;
  }
  gap: 30px;
`;

export default memo(OrderList);
