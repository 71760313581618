import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { classNames } from 'Utils/TailwindHelpers';

export type DropdownSelectOption = {
  id: number;
  name: string;
};

type DropdownSelectProps = {
  initialValue?: number;
  onChange: (
    selectedItem: DropdownSelectOption,
    initializeCall?: boolean
  ) => void;
  options: DropdownSelectOption[];
};

function DropdownSelect({
  initialValue,
  onChange,
  options,
}: DropdownSelectProps): JSX.Element {
  const [selected, setSelected] = useState<DropdownSelectOption>(options[0]);
  const [initialized, setInitialized] = useState(false);

  const handleChange = useCallback(
    (selectedItem: DropdownSelectOption, initializeCall?: boolean): void => {
      setSelected(selectedItem);
      onChange(selectedItem, initializeCall);
    },
    [onChange]
  );

  useEffect(() => {
    if (!initialValue) {
      setInitialized(true);
    } else if (!initialized && initialValue && options.length > 1) {
      const selectedOption = options.find(
        (option) => option.id === initialValue
      );
      if (selectedOption) {
        setInitialized(true);
        handleChange(selectedOption, true);
      }
    }
  }, [handleChange, initialized, initialValue, options]);

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }): JSX.Element => (
        <>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm'>
              <span className='flex items-center'>
                <span className='ml-3 block truncate'>{selected.name}</span>
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.name}
                    className={({ active }): string =>
                      classNames(
                        active ? 'text-white bg-primary-500' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }): JSX.Element => (
                      <>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default DropdownSelect;
