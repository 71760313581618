import React from 'react';
import styled from 'styled-components';

type MessageProps = {
  children: React.ReactNode;
  color?: string;
};

const defaultBackground = '#333333';

function Message({
  children,
  color = defaultBackground,
}: MessageProps): JSX.Element {
  return <MessageContainer color={color}>{children}</MessageContainer>;
}

const MessageContainer = styled.div`
  background-color: ${(props): string => props.color || defaultBackground};
  color: white;
  margin-top: 20px;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: none;
  overflow-wrap: break-word;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  line-height: 24px;
  border: 0;
  border-radius: 3px;
`;

export default Message;
