import { CheckIcon } from '@heroicons/react/24/outline';
import Button from 'Components/common/button/Button';
import ComplexSelect from 'Components/common/complex-select/ComplexSelect';
import DotLoader from 'Components/common/dot-loader/DotLoader';
import Tooltip from 'Components/common/tooltip/Tooltip';
import { useCartContext } from 'Contexts/CartContext';
import { UserContext } from 'Contexts/UserContext';
import { useGetSubscriptions } from 'Hooks/useGetProducts';
import React, { memo, useContext, useEffect, useState } from 'react';
import { formatMoney } from 'Utils/formatMoney';
import { classNames } from 'Utils/TailwindHelpers';
import {
  defaultImage,
  PRODUCT_TYPE,
} from '../../../shared/helpers/subscription.helpers';
import { Description, Subtitle } from './styled.general';
import 'typeface-poppins';
import 'typeface-roboto';

function Subscriptions(): JSX.Element {
  const { subscriptionsList, isFetching } = useGetSubscriptions();
  const { addItem, productItems, isProductInBasket, getSubscriptionFromCart } =
    useCartContext();

  const [selectedVariants, setSelectedVariants] = useState<any>([]);
  const isSomeSubscriptionInCart = !!getSubscriptionFromCart();

  const { fullUser: fullUserInfo } = useContext(UserContext);

  const clearVariationsSelect = (): void => {
    setSelectedVariants([]);
  };

  useEffect(() => {
    if (!productItems?.length) {
      clearVariationsSelect();
    }
  }, [productItems]);

  const isSomeProductBought = (subscription: string | number): boolean => {
    const currentItem = getSelectedVariantOfSubscription(subscription)?.id;
    if (!fullUserInfo?.products) return false;
    return !!fullUserInfo.products.find(
      (item: string | number) => item === +currentItem
    );
  };

  function getCartVariantOfSubscription(item: any): any {
    let res;
    outer: for (const item1 of productItems) {
      for (const item2 of item.variations) {
        if (Number(item1.variationId) === +item2.id) {
          res = item2;
          break outer;
        }
      }
    }
    return res || {};
  }

  function addItemClickHandler(item: any): void {
    const selectVariant = selectedVariants.find(
      (variant: any) => variant?.name === `select_${item.id}`
    )?.id;

    if (selectVariant && item.type === PRODUCT_TYPE.variableSubscription) {
      addItem({
        id: item?.id,
        variationId: selectVariant,
        type: item?.type,
      });
    } else {
      addItem({ id: item?.id, variationId: null, type: item?.type });
    }
    if (item.type === PRODUCT_TYPE.subscription) {
      clearVariationsSelect();
    }
  }

  // unique side logic change dropdown value callback
  function complexSelectCallback(name: string, selectedValue: string): void {
    let updatedVariants = selectedVariants?.length ? [...selectedVariants] : [];
    // for selections before add to cart remove last select value from an selections array
    updatedVariants = updatedVariants.filter((item: any) => item.name !== name);
    // set new value to the selected set from all subscription dropdowns array
    updatedVariants.push({ name, id: selectedValue });
    setSelectedVariants(updatedVariants);
  }

  function getSelectedVariantOfSubscription(item: any): any | null {
    if (!item?.variations || !item?.variations?.length) return item;

    // first of all find in selected dropdown items
    for (const item1 of selectedVariants) {
      for (const variation of item.variations) {
        if (Number(item1.id) === Number(variation.id)) {
          return variation;
        }
      }
    }
    // after try to find in shopping cart product
    for (const item1 of productItems) {
      for (const variation of item.variations) {
        if (Number(item1.variationId) === Number(variation.id)) {
          return variation;
        }
      }
    }

    // if no selected variant for dropdown
    return item;
  }
  const getVariantTitle = (variant: any): string => {
    return `${
      variant.period === 'year'
        ? 'Annual'
        : variant.period === 'month'
        ? 'Monthly'
        : ''
    } ${variant.title}`;
  };
  const getDescription = (subscription: any): string => {
    const {
      shortDescription: productShortDescription,
      description: productFullDescription,
    } = subscription;

    const selectedVariant = getSelectedVariantOfSubscription(subscription);
    if (!selectedVariant || selectedVariant?.id === subscription?.id) {
      return productShortDescription || productFullDescription || '';
    }

    return (
      selectedVariant?.shortDescription ||
      selectedVariant?.description ||
      productShortDescription ||
      productFullDescription ||
      ''
    );
  };

  return (
    <>
      <Subtitle className='subtitle'>Subscriptions</Subtitle>

      <div className='flex flex-1 justify-center items-center'>
        {isFetching && <DotLoader />}
      </div>

      <ul className='grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'>
        {!isFetching && subscriptionsList?.length
          ? subscriptionsList?.map((subscription: any) => {
              return (
                <div key={subscription?.id}>
                  <li className='rounded shadow-lg'>
                    <div className='group aspect-w-16 aspect-h-9 block w-full overflow-hidden'>
                      <img
                        src={subscription?.image || defaultImage}
                        alt='Product'
                        className='pointer-events-none object-cover group-hover:opacity-75'
                      />
                      <a
                        type='button'
                        className='absolute inset-0 focus:outline-none'
                      >
                        <span className='sr-only'>
                          View details for {subscription?.title}
                        </span>
                      </a>
                    </div>

                    <div className='py-[16px] px-[20px]'>
                      <a
                        className='no-underline visited:no-underline'
                        href={subscription?.permalink}
                      >
                        <div className='text-course-title text-base text-center font-semibold text-ellipsis pb-[16px]'>
                          {subscription?.title}
                        </div>
                        <div className='pb-[8px] text-sm text-center'>
                          {formatMoney(
                            subscription?.variations?.length
                              ? getSelectedVariantOfSubscription(subscription)
                                  ?.price
                              : subscription?.price
                          )}
                        </div>
                        <div className='pb-[8px] text-sm'>
                          {subscription.type ===
                          PRODUCT_TYPE.variableSubscription ? (
                            <Description
                              dangerouslySetInnerHTML={{
                                __html: getDescription(subscription),
                              }}
                            />
                          ) : (
                            <Description
                              dangerouslySetInnerHTML={{
                                __html:
                                  subscription?.shortDescription ||
                                  subscription?.title,
                              }}
                            />
                          )}
                        </div>
                      </a>

                      {subscription?.type === 'variable-subscription' &&
                      subscription.variations?.length ? (
                        <div className='mt-[5px]'>
                          <ComplexSelect
                            name={`select_${subscription.id}`}
                            callback={complexSelectCallback}
                            disabled={isSomeSubscriptionInCart}
                            selectedValue={
                              selectedVariants.find(
                                (item: any) =>
                                  item?.name === `select_${item.id}`
                              )?.id ||
                              getCartVariantOfSubscription(subscription)?.id
                            }
                            options={subscription?.variations?.map(
                              (variant: {
                                id: string;
                                title: string;
                                slug: string;
                              }) => ({
                                id: variant.id,
                                name: getVariantTitle(variant),
                              })
                            )}
                          />
                        </div>
                      ) : subscription.variations ? (
                        <div className='pt-[8px] text-sm text-gray-500'>
                          No variations
                        </div>
                      ) : null}
                      <div className='group relative flex justify-center'>
                        {isSomeProductBought(subscription) ? (
                          <Tooltip
                            simple
                            warning
                            message='You have already bought this product.'
                          />
                        ) : null}
                        {isSomeSubscriptionInCart ? (
                          <Tooltip
                            simple
                            message='You can add to cart only one subscription.'
                          />
                        ) : null}
                        <div
                          className={classNames(
                            'w-full',
                            isProductInBasket(subscription?.id)
                              ? 'cursor-default'
                              : 'cursor-pointer'
                          )}
                        >
                          <Button
                            size='large'
                            borderColor='green-700'
                            width='w-full'
                            backgroundColor={
                              isProductInBasket(subscription?.id)
                                ? 'bg-neutral-200'
                                : subscription.variations &&
                                  !subscription.variations.length
                                ? 'bg-transparent'
                                : 'bg-white'
                            }
                            textColor={
                              subscription.variations &&
                              !subscription.variations.length
                                ? 'text-gray-300'
                                : 'text-green-700'
                            }
                            isDisabled={
                              isSomeProductBought(subscription) ||
                              isSomeSubscriptionInCart ||
                              isProductInBasket(subscription?.id) ||
                              (subscription.variations &&
                                !subscription.variations.length) ||
                              (subscription.type ===
                                PRODUCT_TYPE.variableSubscription &&
                                getSelectedVariantOfSubscription(subscription)
                                  ?.id === subscription?.id)
                            }
                            onClick={(): void => {
                              addItemClickHandler(subscription);
                            }}
                          >
                            {!isProductInBasket(subscription?.id) ? (
                              'Add to cart'
                            ) : (
                              <>
                                <CheckIcon
                                  className='block h-6 w-6 mr-1'
                                  aria-hidden='true'
                                />
                                In basket
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })
          : null}
      </ul>
    </>
  );
}

export default memo(Subscriptions);
