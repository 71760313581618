import Button from 'Components/common/button/Button';
import Input from 'Components/common/input/Input';
import Message from 'Components/common/message/Message';
import PrivacyPolicy from 'Components/features/privacy-policy/PrivacyPolicy';
import { useSetNewPassword } from 'Hooks/useSetNewPassword';
import React, { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

function ResetPassword(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const password1Ref = useRef<HTMLInputElement | null>(null);
  const password2Ref = useRef<HTMLInputElement | null>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const rpKey = queryParams.get('key') || '';
  const rpLogin = queryParams.get('login') || '';
  const action = queryParams.get('action');
  const headerText =
    action === 'newaccount' ? 'Set Password' : 'Reset Password';
  const [submittingPassword, setSubmittingPassword] = useState(false);

  const { mutateAsync: savePassword } = useSetNewPassword();
  const saveNewPassword = async (): Promise<void> => {
    setSubmittingPassword(true);
    setErrorMessage('');
    setSuccessMessage('');
    const password1 =
      password1Ref.current instanceof HTMLInputElement
        ? password1Ref.current.value
        : '';
    const password2 =
      password2Ref.current instanceof HTMLInputElement
        ? password2Ref.current.value
        : '';
    if (password1 === '' || password2 === '') {
      setSubmittingPassword(false);
      setErrorMessage('Please enter a password.');
      return;
    }

    if (password1 !== password2) {
      setSubmittingPassword(false);
      setErrorMessage('Passwords do not match.');
      return;
    }
    const resp = await savePassword({
      pass1: password1,
      pass2: password2,
      rp_key: rpKey,
      rp_login: rpLogin,
    });
    setSubmittingPassword(false);
    if (resp.error) {
      setErrorMessage(resp.error);
    } else {
      if (resp.success === true) {
        setSuccessMessage(
          'Successfully updated password! Redirecting to login page in 5 seconds...'
        );
        setTimeout(() => {
          window.location.href = '/login';
        }, 5000);
      }
    }
  };

  return (
    <>
      <HeaderText>{headerText}</HeaderText>
      <Container>
        <Input
          id='password1'
          name='password1'
          placeholder='Add New Password'
          inputRef={password1Ref}
          type='password'
        />
        <Input
          id='password2'
          name='password2'
          placeholder='Retype New Password'
          inputRef={password2Ref}
          type='password'
          onKeyUp={saveNewPassword}
        />
      </Container>
      {errorMessage && <Message color='#EF3E46'>{errorMessage}</Message>}
      {successMessage && <Message color='#27AE60'>{successMessage}</Message>}
      <Button
        flex={false}
        onClick={saveNewPassword}
        loading={submittingPassword}
      >
        Save
      </Button>
      <PrivacyPolicy />
    </>
  );
}

const Container = styled.div`
  padding-top: 25px;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: rgb(77, 92, 109);
`;

export default ResetPassword;
