import Logout from 'Components/features/logout/Logout';
import React from 'react';
import styled from 'styled-components';
import CheckEmail from '../components/features/check-email/CheckEmail';
import ForgotPassword from '../components/features/forgot-password/ForgotPassword';
import Login from '../components/features/login/Login';
import ResetPassword from '../components/features/reset-password/ResetPassword';

function LoginPage(): JSX.Element {
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('action') || '';
  const checkEmail = queryParams.get('checkemail') || '';

  function renderContent(
    action: string,
    checkEmail: string
  ): JSX.Element | null {
    if (checkEmail === 'confirm') {
      return <CheckEmail />;
    }
    switch (action) {
      case 'lostpassword':
        return <ForgotPassword />;
      case 'logout':
        return <Logout />;
      case 'rp':
      case 'newaccount':
        return <ResetPassword />;
      default:
        return <Login />;
    }
  }

  return (
    <>
      <div className='flex flex-1 h-[790px]'>
        <div className='w-0 sm:w-2/4 bg-pixel-pattern bg-cover' />
        <div className='flex w-full sm:w-2/4 mt-[200px] ml-[65px]'>
          <LoginContent>
            <Logo src='https://d10heettfeutgf.cloudfront.net/uploads/2021/05/logo-100px.png' />
            <RenderedContent>
              {renderContent(action, checkEmail)}
            </RenderedContent>
          </LoginContent>
        </div>
      </div>
    </>
  );
}

const LoginContent = styled.div`
  flex-direction: column;
  display: flex;
  width: 270px;
`;

const Logo = styled.img`
  height: 79px;
  width: 79px;
  display: flex;
  align-self: center;
`;

const RenderedContent = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
`;

export default LoginPage;
