import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { classNames } from 'Utils/TailwindHelpers';

type TablePageControlProps = {
  direction: 'previous' | 'next';
};

function TablePageControl({ direction }: TablePageControlProps): JSX.Element {
  const anchorStyle =
    'relative inline-flex items-center px-2 py-2 text-bodySmall text-gray-300 font-regular';

  return (
    <div className={anchorStyle}>
      {direction === 'previous' ? (
        <>
          <span className='sr-only'>Previous</span>
          <ArrowLongLeftIcon
            className='mr-3 h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </>
      ) : (
        <>
          <span className='sr-only'>Next</span>
          <ArrowLongRightIcon
            className='ml-3 h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </>
      )}
    </div>
  );
}

const containerClassName = 'flex items-center justify-center';
const pageBaseClasses =
  'relative inline-flex items-center h-7 min-w-[28px] px-2 py-2 text-bodySmall font-regular rounded-md justify-center';
const pageClassName = classNames(pageBaseClasses, 'text-gray-400');
const pageActiveClassName = classNames(
  pageBaseClasses,
  'z-10 bg-primary-500 !text-white'
);
const breakClassName = 'text-gray-400 mx-1';
const disabledClassName = 'hidden';

type PaginationProps = {
  currentPage: number;
  onPageChange: (page: number, initializeCall: boolean) => void;
  perPage: number;
  totalItems: number;
};

function Pagination({
  currentPage,
  onPageChange,
  perPage,
  totalItems,
}: PaginationProps): JSX.Element {
  const numPages = Math.ceil(totalItems / perPage);

  return (
    <div className='py-3 flex items-center justify-between min-h-[76px] mt-auto'>
      <div className='sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <nav
            className='relative z-0 inline-flex -space-x-px items-center'
            aria-label='Pagination'
          >
            <ReactPaginate
              previousLabel={<TablePageControl direction='previous' />}
              nextLabel={<TablePageControl direction='next' />}
              pageCount={numPages >= currentPage ? numPages : currentPage} //Avoid library warning for when pageCount isn't loaded yet
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              containerClassName={containerClassName}
              pageClassName={pageClassName}
              activeClassName={pageActiveClassName}
              breakClassName={breakClassName}
              disabledClassName={disabledClassName}
              forcePage={currentPage - 1}
              onPageChange={(page): void =>
                onPageChange(page.selected + 1, false)
              }
            />
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
