export const formatDate = (dateStr: any): string => {
  const date = new Date(+dateStr * 1000);
  if (!date) return '';
  const month = MONTHS[`${date.getMonth() + 1}`];
  const day =
    date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1;
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

const MONTHS: { [key: string]: string } = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'Septembers',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

// 1	January	Jan.	31	winter
// 2	February	Feb.	28/29
// 3	March	Mar.	31	spring
// 4	April	Apr.	30
// 5	May	May	31
// 6	June	Jun.	30	summer
// 7	July	Jul.	31
// 8	August	Aug.	31
// 9	September	Sep.	30	autumn
// 10	October	Oct.	31
// 11	November	Nov.	30
// 12	December	Dec.	31	winter
