import Button from 'Components/common/button/Button';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from 'Utils/formatDate';
import { formatMoney } from 'Utils/formatMoney';
import { CartProduct } from '../../../types/cart.product';
import 'typeface-poppins';
import 'typeface-roboto';

function RelatedSubscriptions({ order }: any): JSX.Element {
  const navigate = useNavigate();

  const orderBtnHandler = (): void => {
    navigate('/dashboard');
  };

  // const subscriptions = ['Subscription', 'Status', 'Next payment', 'Total', ''];
  // const keys = ['id', 'status', 'nextPayment', 'Total', ''];

  return (
    <>
      <Title className='text-left'>Related subscriptions</Title>

      <List>
        {order?.subscriptions?.length > 0 &&
          order.subscriptions.map((item: CartProduct, idx: number) => (
            <SubscriptionTable key={item?.id || idx}>
              <div className='border-l-[1px] border-r-[1px] border-t-[1px] border-[#E4E4E4]'>
                <div className='uppercase font-semibold text-gray-500'>
                  Subscription
                </div>
                <div># {item?.id}</div>
              </div>
              <div className='border-t-[1px] border-l-[1px] border-r-[1px] border-[#E4E4E4]'>
                <div className='uppercase font-semibold text-gray-500'>
                  Status
                </div>
                <div>{item?.status}</div>
              </div>
              <div className='border-t-[1px] border-l-[1px] border-r-[1px] border-[#E4E4E4]'>
                <div className='uppercase font-semibold text-gray-500'>
                  Next payment
                </div>
                <div>{formatDate(item?.nextPayment)}</div>
              </div>
              <div className='border-t-[1px] border-l-[1px] border-r-[1px] border-[#E4E4E4]'>
                <div className='uppercase font-semibold text-gray-500'>
                  Total
                </div>
                <div>
                  {formatMoney(item?.total)} / {item?.trialPeriod}
                </div>
              </div>
              <div
                style={{ gridColumn: '1/3' }}
                className='border-l-[1px] border-r-[1px] border-[#E4E4E4] border-t-[1px] justify-end border-b-[1px] lg:border-b-[3px]'
              >
                <div className='mb-[14px]'></div>
                <div className='mb-[14px] px-[10px] border-[#E4E4E4] hidden justify-end sm:flex'>
                  <Button
                    borderRadius='rounded-full'
                    size='large'
                    flex={false}
                    fontWeight='think'
                    backgroundColor='bg-green-600'
                    onClick={orderBtnHandler}
                  >
                    Get Started Now
                  </Button>
                </div>
              </div>
            </SubscriptionTable>
          ))}
      </List>
    </>
  );
}

const List = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;
const Title = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
`;

const SubscriptionTable = styled.div`
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  @media screen and (max-width: 576px) {
    box-shadow: none;
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
  & > div {
    display: grid;
    height: 100%;
    align-items: center;
    min-height: 50px;
    grid-template-columns: repeat(2, 50%);
    & > div {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    font-family: 'Poppins', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    div {
      button {
        font-size: 12px;
        padding: 5px 10px;
        width: 150px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 13px;
    button {
      font-size: 12px;
      padding: 5px 10px;
      min-width: 150px;
      position: absolute;
      left: calc((50% - 150px / 2));
    }
  }
  & > * {
    text-align: left;
  }
`;

export default memo(RelatedSubscriptions);
