import { useApi } from 'Contexts/ApiContext';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export type UserCourseDetail = {
  courseStarted: number;
  enrolled: boolean;
  lastActivity: number;
  lastCompletedLessonId: number;
  lessonActivity: LessonActivity[];
  stepsCompleted: number;
};

export type LessonActivity = {
  lessonId: number;
  completed: string;
};

type UserCourseDetailResponse = {
  userCourseDetailData: UserCourseDetail | any;
  isFetching: boolean;
  refetch: any;
};

export const useGetUserCourseDetail = (
  courseId: number,
  isLoggedIn: boolean | undefined
): UserCourseDetailResponse => {
  const api = useApi();
  const fetchUserCourseDetail = async (): Promise<UserCourseDetail> =>
    (
      await api.post('/api/v1/user-course-detail.php', {
        courseId,
      })
    ).data;
  const placeholderData = useMemo(() => {
    return {};
  }, []);
  const {
    data: userCourseDetailData,
    isFetching,
    refetch,
  } = useQuery(['courseDetail', courseId], fetchUserCourseDetail, {
    enabled: isLoggedIn,
    onError: (error) => console.error(error),
    placeholderData: placeholderData,
  });
  return { userCourseDetailData, isFetching, refetch };
};
