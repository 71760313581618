import { StarIcon } from '@heroicons/react/24/outline';
import LessonBar from 'Components/features/lesson-bar/LessonBar';
import { UserContext } from 'Contexts/UserContext';
import { format } from 'date-fns';
import {
  useGetCourseDetail,
  CourseDetail,
  EnrolledUser,
  LessonDetail,
} from 'Hooks/useGetCourseDetail';
import {
  useGetUserCourseDetail,
  UserCourseDetail,
} from 'Hooks/useGetUserCourseDetail';
import { useSetCourseFavorite } from 'Hooks/useSetCourseFavorite';
import { useSetEnrollInCourse } from 'Hooks/useSetEnrollInCourse';
import parse from 'html-react-parser';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../components/common/button/Button';
import Layout from '../components/ui/layouts/Layout';

function Course(): JSX.Element {
  const { isLoggedIn, setUser, user } = useContext(UserContext);
  const params = useParams();
  const { courseDetailData, refetch: refetchCourseDetailData } =
    useGetCourseDetail(params.post_name || '');
  const { userCourseDetailData, refetch: refetchUserCourseDetailData } =
    useGetUserCourseDetail(courseDetailData.courseId, isLoggedIn);
  const { mutateAsync: setCourseFavorite } = useSetCourseFavorite();
  const { mutateAsync: setEnrollInCourse } = useSetEnrollInCourse();
  const enrollInCourse = async (courseId: number): Promise<void> => {
    const resp = await setEnrollInCourse({ courseId });
    if (resp.success) {
      await refetchCourseDetailData();
      await refetchUserCourseDetailData();
    } else {
      console.log(resp.error);
    }
  };
  const updateCourseFavorite = async (courseId: number): Promise<void> => {
    const resp = await setCourseFavorite({ courseId });
    if (setUser) {
      setUser({
        ...user,
        favoriteCourses: resp.favoriteCourses,
      });
    }
  };
  const hideCourseContent = true;
  function getCtaText(
    lessonId: number,
    lessonDetails: LessonDetail[],
    isLoggedIn: undefined | boolean
  ): string {
    if (!isLoggedIn) return 'Login to Enroll';
    return isOnFirstLesson(lessonId, lessonDetails)
      ? 'Start Course'
      : 'Continue';
  }

  function getCompletionPercentage(
    courseDetailData: CourseDetail,
    userCourseDetailData: UserCourseDetail
  ): number {
    const stepsCompleted = userCourseDetailData?.lessonActivity?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.completed),
      0
    );
    return Math.ceil(100 * (stepsCompleted / courseDetailData.courseStepCount));
  }

  function getCtaUrl(lessonId: number, lessonDetails: LessonDetail[]): string {
    if (!isLoggedIn) return '/login';
    let lessonUrl = lessonDetails?.find(
      (lessonDetail) => lessonDetail.id === lessonId
    )?.lessonUrl;
    if (!lessonUrl) {
      lessonUrl = lessonDetails?.length > 0 ? lessonDetails[0]?.lessonUrl : '';
    }
    let next = false;
    lessonDetails?.forEach((lessonDetail) => {
      if (next) {
        lessonUrl = lessonDetail?.lessonUrl;
        next = false;
      }
      if (lessonDetail.id === lessonId) {
        next = true;
      }
    });

    return lessonUrl;
  }
  function getDate(timestamp: number): string {
    return format(new Date(timestamp * 1000), 'PPP');
  }
  function isCourseFavorite(courseId: number): boolean {
    return !!user?.favoriteCourses?.includes(parseInt(String(courseId)));
  }
  function isOnFirstLesson(
    lessonId: number,
    lessonDetails: LessonDetail[]
  ): boolean {
    if (!lessonId) return true;
    return lessonDetails?.length > 0 ? lessonDetails[0].id === lessonId : false;
  }
  const courseCompletionPercentage = userCourseDetailData.stepsCompleted
    ? getCompletionPercentage(courseDetailData, userCourseDetailData)
    : 0;
  const lastActivity =
    userCourseDetailData.lastActivity == 0
      ? 'Last Activity Unknown'
      : userCourseDetailData.lastActivity
      ? `Last Activity on ${getDate(userCourseDetailData.lastActivity)}`
      : `0/${courseDetailData.courseStepCount} Complete`;

  useEffect(() => {
    document.title = `${courseDetailData.courseTitle} - Smart Game Piano`;
  }, [courseDetailData]);

  return (
    <Layout>
      <div className='flex flex-col'>
        <div className='h-[375px] md:h-[431px] w-screen opacity-40 absolute bg-banner-before left-0 z-10 bg-gradient-to-tb from-white to-black'></div>
        <div className='h-[375px] bg-cover bg-no-repeat md:h-[431px] bg-[url("https://d10heettfeutgf.cloudfront.net/uploads/2021/03/Screenshot-2021-03-17-at-14.44.20.png")]'>
          <div className='flex justify-center h-full p-[20px] m:p-0'>
            <div className='h-full  flex items-center  max-w-[1200px]'>
              <div className='z-20 text-white md:mr-[480px]'>
                <div className='text-base'>
                  {courseDetailData?.category?.toUpperCase()}
                </div>
                <h1 className='leading-[60px] py-[10px] text-6xl font-semibold'>
                  {courseDetailData.courseTitle}
                </h1>
                {courseDetailData.sheetMusicLink && (
                  <Button size='large' textColor='text-black'>
                    <a
                      className=''
                      href={courseDetailData.sheetMusicLink}
                      rel='noreferrer'
                      target='_blank'
                    >
                      Get Sheet Music
                    </a>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='h-full flex flex-row justify-center'>
          <div className='flex flex-col pt-[20px] md:flex-row-reverse w-full max-w-[1200px] p-[25px] md:p-0'>
            <div className='z-20 md:max-w-[380px] w-full '>
              <div className='md:sticky md:top-[90px]'>
                <img
                  className='object-cover w-full md:mt-[-317px]'
                  src={courseDetailData.courseImageUrl}
                />
                <div className='bg-white px-[30px] pb-[20px] shadow-md'>
                  <div className='flex flex-row pt-[20px] justify-center'>
                    {courseDetailData?.usersEnrolled
                      ?.slice(0, 3)
                      .map((enrolledUser: EnrolledUser) => (
                        <img
                          className='max-w-[28px] rounded-full mr-[-8px]'
                          key={enrolledUser.id}
                          src={enrolledUser.gravatarUrl}
                        />
                      ))}
                    {courseDetailData?.usersEnrolled?.length > 3 ? (
                      <div className='ml-[20px]'>
                        +{courseDetailData?.usersEnrolled.length - 3} enrolled
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {!isOnFirstLesson(
                    userCourseDetailData?.lastCompletedLessonId,
                    courseDetailData?.lessonDetails
                  ) && (
                    <div className='rounded-full bg-[#E2E7ED] text-[#728188] mt-[20px] text-center p-[3px] text-[11px] font-semibold'>
                      {isLoggedIn ? 'IN PROGRESS' : 'NOT ENROLLED'}
                    </div>
                  )}
                  <div className='flex flex-row justify-center'>
                    {userCourseDetailData?.enrolled && (
                      <Button
                        size='large'
                        borderRadius='rounded-full'
                        width='w-full'
                      >
                        <a
                          className=''
                          href={getCtaUrl(
                            userCourseDetailData?.lastCompletedLessonId,
                            courseDetailData?.lessonDetails
                          )}
                        >
                          {getCtaText(
                            userCourseDetailData?.lastCompletedLessonId,
                            courseDetailData?.lessonDetails,
                            isLoggedIn
                          )}
                        </a>
                      </Button>
                    )}
                    {!userCourseDetailData?.enrolled && (
                      <div className='flex flex-col w-full'>
                        <div className='rounded-full w-full bg-[#E2E7E3] text-[#728188] text-center mt-[15px]'>
                          Not Enrolled
                        </div>
                        <Button
                          size='large'
                          borderRadius='rounded-full'
                          width='w-full'
                          onClick={(): Promise<void> =>
                            enrollInCourse(courseDetailData.courseId)
                          }
                        >
                          Enroll
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex-col pt-[35px] md:pr-[95px]'>
              <div className='flex flex-col'>
                {!hideCourseContent && courseDetailData.courseContent && (
                  <div>{parse(courseDetailData.courseContent)}</div>
                )}
                <div className='flex flex-row justify-between'>
                  <div className='text-lg font-semibold'>COURSE CONTENT</div>
                  {isLoggedIn && (
                    <StarIcon
                      className={`h-8 w-8 mr-3 stroke-primary-500 hover:text-primary-500 cursor-pointer ${
                        isCourseFavorite(courseDetailData.courseId)
                          ? 'fill-primary-500'
                          : ''
                      }`}
                      aria-hidden='true'
                      onClick={(): Promise<void> =>
                        updateCourseFavorite(courseDetailData.courseId)
                      }
                    />
                  )}
                </div>
                {isLoggedIn && (
                  <div className='flex flex-row justify-end'>
                    <div className='flex flex-col'>
                      <div className='font-semibold text-sm'>
                        {courseCompletionPercentage}% Complete
                      </div>
                      <div className='text-sm'>{lastActivity}</div>
                    </div>
                  </div>
                )}
                <div className='mt-[5px] mb-[48px]'>
                  {courseDetailData.lessonDetails?.map(
                    (lessonDetail: LessonDetail) => (
                      <LessonBar
                        key={lessonDetail.id}
                        courseDetail={courseDetailData}
                        isLoggedIn={!!isLoggedIn}
                        lessonDetail={lessonDetail}
                        userCourseDetail={userCourseDetailData}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Course;
