import { useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type PasswordResetParams = {
  user_email: string;
};

type PasswordResetResponse = {
  error: string;
  success: boolean;
  email: string;
  user: boolean;
} | null;

export const useResetPassword = (): UseMutationResult<
  PasswordResetResponse,
  unknown,
  PasswordResetParams
> => {
  const api = useUnauthenticatedApi();

  const resetPassword = async (
    payload: PasswordResetParams
  ): Promise<PasswordResetResponse> =>
    (await api.post('/api/v1/reset-password.php', payload)).data;

  return useMutation(async (payload: PasswordResetParams) =>
    resetPassword(payload)
  );
};
