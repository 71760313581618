import Button from 'Components/common/button/Button';
import Input from 'Components/common/input/Input';
import Link from 'Components/common/link/Link';
import Message from 'Components/common/message/Message';
import LoginHeader from 'Components/features/login-header/LoginHeader';
import PrivacyPolicy from 'Components/features/privacy-policy/PrivacyPolicy';
import { useLogin } from 'Hooks/useLogin';
import { useRef } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

function Login(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const { mutateAsync: doLogin } = useLogin();
  const login = async (): Promise<void> => {
    setLoggingIn(true);
    setErrorMessage('');
    const username =
      usernameRef.current instanceof HTMLInputElement
        ? usernameRef.current.value
        : '';
    const password =
      passwordRef.current instanceof HTMLInputElement
        ? passwordRef.current.value
        : '';
    if (username === '') {
      setLoggingIn(false);
      setErrorMessage('Please enter a username.');
      return;
    }
    if (password === '') {
      setLoggingIn(false);
      setErrorMessage('Please enter a password.');
      return;
    }
    const loginResponse = await doLogin({
      username,
      password,
    });
    if (loginResponse.error) {
      setLoggingIn(false);
      setErrorMessage(loginResponse.error);
    }
  };

  function executeLogin(event: React.KeyboardEvent): void {
    if (event.key === 'Enter') {
      login();
    }
  }

  return (
    <div className='justify-center'>
      <LoginHeader />
      <LoginContainer>
        <Input
          id='username'
          name='username'
          placeholder='Username'
          inputRef={usernameRef}
        />
        <Input
          id='password'
          name='password'
          placeholder='Password'
          inputRef={passwordRef}
          type='password'
          onKeyUp={executeLogin}
        />
      </LoginContainer>
      {errorMessage && <Message color='#EF3E46'>{errorMessage}</Message>}
      <ForgotPasswordContainer>
        <Link href='/login?action=lostpassword'>Forgot Password?</Link>
      </ForgotPasswordContainer>
      <Button flex={false} onClick={login} loading={loggingIn} width='w-full'>
        Log In
      </Button>
      <PrivacyPolicy />
    </div>
  );
}

const ForgotPasswordContainer = styled.div`
  align-items: end;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`;

const LoginContainer = styled.div`
  padding-top: 25px;
`;

export default Login;
