import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorPage(): JSX.Element {
  const error = useRouteError();
  console.error(error);
  function renderError(error: unknown): JSX.Element {
    if (isRouteErrorResponse(error)) {
      return <i>{error.statusText}</i>;
    } else {
      return <i>Unknown Error</i>;
    }
  }

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>{renderError(error)}</p>
    </div>
  );
}
