import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import '../general.styles.css';

export const StripeForm = () => {
  return (
    <>
      <InputContainer>
        <Label>Card number *</Label>
        <CardNumberElement options={elementOptions} />
      </InputContainer>
      <ContainerTwoCol>
        <InputContainer>
          <Label>Expiration date *</Label>
          <CardExpiryElement options={elementOptions} />
        </InputContainer>
        <InputContainer>
          <Label>CVC code *</Label>
          <CardCvcElement options={elementOptions} />
        </InputContainer>
      </ContainerTwoCol>
    </>
  );
};

const elementOptions = {
  style: {
    base: {
      color: '#707070',
      backgroundColor: '#FFFFFF',
      fontSize: '16px',
      '::placeholder': {
        color: '#B3B3B3',
      },
    },
    invalid: {
      color: '#fa755a',
      backgroundColor: '#FFFFFF',
      fontSize: '16px',
    },
  },
};

const Label = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 4px;
`;
const ContainerTwoCol = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
const InputContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
`;
