import { useApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type CourseFavoriteParams = {
  courseId: number;
};

type CourseFavoriteResponse = {
  favoriteCourses: number[];
};

export const useSetCourseFavorite = (): UseMutationResult<
  CourseFavoriteResponse,
  unknown,
  CourseFavoriteParams
> => {
  const api = useApi();

  const setCourseFavorite = async (
    payload: CourseFavoriteParams
  ): Promise<CourseFavoriteResponse> => {
    return (await api.post('/api/v1/course-favorite.php', payload)).data;
  };

  return useMutation(async (payload: CourseFavoriteParams) =>
    setCourseFavorite(payload)
  );
};
