import axios, { AxiosInstance, AxiosResponse } from 'axios';
import React, { createContext, useContext, useMemo } from 'react';

type ApiContextValue = {
  unauthenticatedInstance: AxiosInstance;
  instance: AxiosInstance;
};

type ApiContextProps = {
  children: JSX.Element;
};

const ApiContext = createContext<ApiContextValue | null>(null);

export function ApiProvider({ children }: ApiContextProps): JSX.Element {
  const apiUrl = process.env.API_URL;

  function errorFunction(error: any): Promise<never> | void {
    // check domain and ensure it's ours
    // https://gist.github.com/yajra/5f5551649b20c8f668aec48549ef5c1f
    if (error.response && 401 === error.response.status) {
      (window as Window).location = '/login';
    } else {
      return Promise.reject(error);
    }
  }

  function passThroughAxios(response: AxiosResponse): AxiosResponse {
    return response;
  }

  const unauthenticatedInstance = useMemo(() => {
    const axiosConfig = { baseURL: apiUrl };
    const unAuthInst = axios.create(axiosConfig);
    if (process.env.NODE_ENV !== 'test') {
      unAuthInst.interceptors.response.use(passThroughAxios, errorFunction);
    }
    return unAuthInst;
  }, [apiUrl]);

  const instance = useMemo(() => {
    const axiosConfig = {
      baseURL: apiUrl,
      withCredentials: false,
    };
    const instance = axios.create(axiosConfig);
    if (process.env.NODE_ENV !== 'test') {
      instance.interceptors.response.use(passThroughAxios, errorFunction);

      instance.interceptors.request.use(
        async (config): Promise<any> => {
          let token = localStorage.getItem('token');
          if (token) {
            token = JSON.parse(token);
          }

          if (!token) {
            (window as Window).location = '/login';
          }
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          };
        },
        (error) => Promise.reject(error)
      );
    }
    return instance;
  }, [apiUrl]);
  return (
    <ApiContext.Provider value={{ instance, unauthenticatedInstance }}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi(): AxiosInstance {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('API is not provided');
  }
  return context.instance;
}

export function useUnauthenticatedApi(): AxiosInstance {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('Unauthenticated API is not provided');
  }
  return context.unauthenticatedInstance;
}
