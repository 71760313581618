import React from 'react';
import styled from 'styled-components';

type ButtonProps = {
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  children: React.ReactNode;
  flex?: boolean;
  fontWeight?: string;
  hover?: string;
  loading?: boolean;
  onClick?: (e: React.MouseEvent | React.TouchEvent) => void;
  size?: string;
  textColor?: string;
  width?: string;
  isDisabled?: boolean;
  className?: string;
};

function Button({
  backgroundColor = 'bg-primary-500',
  borderColor = 'border-transparent',
  borderRadius = 'rounded-md',
  children,
  flex = true,
  fontWeight = 'font-medium',
  hover = '',
  loading = false,
  onClick = (): void => void 0,
  size = 'default',
  textColor = 'text-white',
  width = '',
  isDisabled,
  className,
}: ButtonProps): JSX.Element {
  const defaultClasses = `mt-3.5 ${flex ? 'flex' : ''} ${
    className || ''
  } justify-center ${width} ${backgroundColor} ${borderRadius} border ${borderColor} ${textColor} ${fontWeight} ${hover} shadow-sm`;
  function getClasses(size: string): string {
    switch (size) {
      case 'small':
        return defaultClasses + ' py-2 px-4 text-sm';
      case 'large':
        return defaultClasses + ' py-3 px-6 text-base';
      default:
        return defaultClasses + ' py-2 px-4 text-base';
    }
  }

  return (
    <button
      type='button'
      className={getClasses(size)}
      onClick={onClick}
      disabled={isDisabled || loading}
    >
      {loading ? <Loader /> : children}
    </button>
  );
}

const Loader = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: #fff;
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: calc(50% - 260px);
  animation: shadowRolling 3s linear infinite;

  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    12% {
      box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    25% {
      box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0);
    }
    36% {
      box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
        0px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
    }
    62% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white,
        110px 0 white;
    }
    75% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
        130px 0 white, 120px 0 white;
    }
    87% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0), 130px 0 white;
    }
    100% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
  }
`;

export default Button;
