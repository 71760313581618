import Pagination from 'Components/common/pagination/Pagination';
import { CourseData } from 'Hooks/useGetCourses';
import React from 'react';
import styled from 'styled-components';

type CourseGridProps = {
  courseData: CourseData;
  currentPage: number;
  limit: number;
  onPageChange: (page: number, initializeCall: boolean) => void;
};

function CourseGrid({
  courseData,
  currentPage,
  limit,
  onPageChange,
}: CourseGridProps): JSX.Element {
  return (
    <div className='flex flex-col'>
      <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'>
        {courseData?.courses?.map((course) => (
          <div
            key={course.post_id}
            className='border border-solid rounded shadow-lg'
          >
            <li className='relative'>
              <div className='group aspect-w-16 aspect-h-9 block w-full overflow-hidden'>
                <img
                  src={course.imageUrl}
                  alt=''
                  className='pointer-events-none object-cover group-hover:opacity-75'
                />
                <a
                  href={course.courseUrl}
                  type='button'
                  className='absolute inset-0 focus:outline-none'
                >
                  <span className='sr-only'>
                    View details for {course.title}
                  </span>
                </a>
              </div>
              <div className='py-[16px] px-[20px]'>
                <div className='pb-[8px] text-sm'>7 Lessons</div>
                <a
                  className='no-underline visited:no-underline cursor-pointer'
                  href={course.courseUrl}
                >
                  <div className='text-course-title text-base font-bold text-ellipsis pb-[16px]'>
                    {course.title}
                  </div>
                </a>
                <div className='mb-[16px] cursor-pointer'>
                  <PreviewButton />
                  <div className='text-base font-normal float-left'>
                    Preview
                  </div>
                </div>
              </div>
            </li>
          </div>
        ))}
      </ul>
      <div className='pt-[35px] flex justify-center'>
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          perPage={limit}
          totalItems={courseData?.courseCount}
        />
      </div>
    </div>
  );
}

const PreviewButton = styled.div`
  width: 16px;
  height: 16px;
  border-style: solid;
  box-sizing: border-box;
  border-width: 8px 0px 8px 12px;
  border-color: transparent transparent transparent rgb(39, 174, 96);
  display: inline-flex;
  float: left;
  padding-right: 12px;
`;

export default CourseGrid;
