import Modal from 'Components/common/modal/Modal';
import { useGetPrivacyPolicy } from 'Hooks/useGetPrivacyPolicy';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import styled from 'styled-components';

function PrivacyPolicy(): JSX.Element {
  const [open, setOpen] = useState(false);
  const { privacyPolicyData } = useGetPrivacyPolicy(open);
  return (
    <PrivacyPolicyContainer>
      <div
        className='cursor-pointer text-primary-500'
        onClick={(): void => setOpen(true)}
      >
        Privacy Policy
      </div>
      <Modal open={open} setOpen={setOpen}>
        {privacyPolicyData && parse(privacyPolicyData.html)}
      </Modal>
    </PrivacyPolicyContainer>
  );
}

const PrivacyPolicyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

export default PrivacyPolicy;
