import DotLoader from 'Components/common/dot-loader/DotLoader';
import CourseGrid from 'Components/features/course-grid/CourseGrid';
import Layout from 'Components/ui/layouts/Layout';
import { useGetCourses } from 'Hooks/useGetCourses';
import { useEffect } from 'react';
import React, { useState } from 'react';

function CourseLibrary(): JSX.Element {
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { courseData, isFetching: coursesLoading } = useGetCourses(
    '',
    -1,
    'course',
    limit,
    offset
  );
  function onPageChange(page: number): void {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  }

  useEffect(() => {
    document.title = 'Course Library - Smart Game Piano';
  }, []);

  return (
    <Layout>
      <div className='flex flex-col min-h-[500px] max-w-[1200px] m-auto pb-[75px] w-auto items-center pt-[75px] justify-center'>
        {coursesLoading && <DotLoader />}
        {!coursesLoading && (
          <CourseGrid
            courseData={courseData}
            currentPage={currentPage}
            limit={limit}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </Layout>
  );
}

export default CourseLibrary;
