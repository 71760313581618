import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DotLoader from 'Components/common/dot-loader/DotLoader';
import Checkout from 'Components/features/checkout/Checkout';
import Info from 'Components/features/checkout/Info';
import Layout from 'Components/ui/layouts/Layout';
import { useGetPaymentMethods } from 'Hooks/useGetProducts';
import React from 'react';

export function StripeCheckout() {
  const { gateways } = useGetPaymentMethods();

  const stripePromise = gateways?.stripe?.publicKey
    ? loadStripe(gateways?.stripe?.publicKey)
    : null;

  return (
    <Layout background='#ffffff' prefooter={<Info />}>
      <div className='px-[15px] md:px-[30px] xl:px-0 flex flex-col min-h-[500px] max-w-[1200px] m-auto pb-[45px] xl:pb-[82px] poppins pt-[45px] xl:pt-[46px]'>
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <Checkout gateways={gateways} />
          </Elements>
        ) : (
          !stripePromise && (
            <div className='flex flex-1 justify-center items-center'>
              <DotLoader />
            </div>
          )
        )}
      </div>
    </Layout>
  );
}
