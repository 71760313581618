import { useApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type Logout = {
  error: string;
  logoutSuccess: boolean;
};

type LogoutError = {
  error: string;
};

type LogoutParams = Record<string, never>;

type LogoutResponse = Logout | LogoutError;

export const useLogout = (): UseMutationResult<
  LogoutResponse,
  unknown,
  LogoutParams
> => {
  const api = useApi();

  const logout = async (payload: LogoutParams): Promise<LogoutResponse> => {
    try {
      return (await api.post('/api/v1/logout.php', payload)).data;
    } catch (error: any) {
      const errorResponse = {
        error: '',
      };
      const code = error.response.data.code;
      switch (code) {
        default:
          errorResponse.error =
            'An unknown error has occurred during logout, please contact support.';
          break;
      }
      return errorResponse;
    }
  };

  return useMutation(async (payload: LogoutParams) => logout(payload), {
    onSuccess: (res): any => {
      const response = {
        error: '',
      };

      if (res.error) {
        response.error = res.error;
        return response;
      } else {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    },
  });
};
