import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from 'Components/common/button/Button';
import CartItem from 'Components/features/cart/CartItem';
import { DetailedProduct, useCartContext } from 'Contexts/CartContext';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatMoney } from 'Utils/formatMoney';
import 'typeface-poppins';
import 'typeface-roboto';

function CartList({ isReadOnly = false }): JSX.Element {
  const {
    removeItem,
    productItems,
    detailedItems: items,
    clearAll,
    getPurchaseAmount,
  } = useCartContext();

  const navigator = useNavigate();
  const cartAmount = getPurchaseAmount();

  return (
    <div className={!isReadOnly ? 'px-[16px]' : ''}>
      {items && items?.length === 0 && (
        <div
          className={
            'flex h-[150px] items-center pt-[12px] justify-center px-3.5 py-5'
          }
          key={0}
        >
          No items
        </div>
      )}

      <div className='flex flex-col gap-[16px] max-h-[250px] overflow-y-auto'>
        {items?.length > 0 &&
          items.map((item: DetailedProduct, idx: number) => (
            <div key={item?.id || idx}>
              <div
                onClick={(e): void => e.preventDefault()}
                className={'text-primary-500'}
              >
                <CartItem
                  isReadOnly={isReadOnly}
                  removeItem={removeItem}
                  product={item}
                />
              </div>
            </div>
          ))}
      </div>

      {!isReadOnly && productItems?.length > 0 && (
        <div className={'flex flex-col sm:flex-row justify-between py-[16px]'}>
          <Button
            textColor='text-dark'
            fontWeight='think'
            backgroundColor='bg-[#E2E7E3]'
            onClick={(): void => clearAll()}
          >
            Clear all
          </Button>
          {!isReadOnly && productItems?.length > 0 && (
            <Total
              className={
                'flex justify-center font-semibold text-gray-600 relative mb-2 top-5'
              }
            >
              Total: {formatMoney(cartAmount)}
            </Total>
          )}
          <Button
            textColor='text-white'
            borderColor={'border-primary-500'}
            fontWeight='think'
            backgroundColor='bg-green-600'
            onClick={(): void => navigator('/checkout')}
          >
            Checkout
          </Button>
        </div>
      )}
    </div>
  );
}

const Total = styled.div`
  font-family: 'Poppins', inherit, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`;

export default memo(CartList);
