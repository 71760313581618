import { EnvelopeIcon, UserIcon, HomeIcon } from '@heroicons/react/20/solid';
import Button from 'Components/common/button/Button';
import DotLoader from 'Components/common/dot-loader/DotLoader';
import OrderList from 'Components/features/order/OrderList';
import RelatedSubscriptions from 'Components/features/order/RelatedSubscriptions';
import Layout from 'Components/ui/layouts/Layout';
import { UserContext } from 'Contexts/UserContext';
import { useGetOrder } from 'Hooks/useGetOrder';
import React, { useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from 'Utils/formatDate';
import { formatMoney } from 'Utils/formatMoney';

export default function OrderPage(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const [order, isFetching] = useGetOrder(params?.id || '');
  const { fullUser: fullUserInfo } = useContext(UserContext);

  const orderBtnHandler = (): void => {
    navigate('/dashboard');
  };

  if (
    // not valid or not user's order
    (!order && !isFetching) ||
    (order && order.customer.id !== fullUserInfo?.id)
  ) {
    return (
      <Layout>
        <div className='flex flex-col min-h-[500px] max-w-[1200px] m-auto pb-[75px] pt-[75px] poppins'>
          <div className='flex justify-center text-gray-400 font-semibold mb-[60px]'>
            No order
          </div>
        </div>
      </Layout>
    );
  }

  // console.log('order: ', order);

  return (
    <Layout>
      <div className='px-[15px] md:px-[30px] flex flex-col min-h-[500px] max-w-[1200px] m-auto pb-[75px] poppins pt-[45px] xl:pt-[75px]'>
        {isFetching ? (
          <div className='flex flex-1 justify-center items-center'>
            <DotLoader />
          </div>
        ) : (
          <>
            <div className='text-xs flex justify-center text-green-600 font-semibold mb-[60px]'>
              {"You're"} starting a FREE TRIAL of one of our plans. You card
              WILL NOT BE charged if you cancel anytime within the next 7 days.
            </div>
            <div className='flex justify-center'>
              <Title $mb='10px' className='text-center'>
                Your order confirmed
              </Title>
            </div>
            <div className='flex justify-center text-center mb-[12px] text-xs sm:text-base'>
              Conglatulations! You&apos;ve unlocked the Exclusive Smartlist
              membership. We&apos;ll send you a confirmation email.
            </div>
            <BtnWrapper className='flex justify-center mb-0 lg:mb-[30px]'>
              <Button
                borderRadius='rounded-full'
                size='large'
                flex={false}
                fontWeight='think'
                backgroundColor='bg-green-600'
                onClick={orderBtnHandler}
              >
                Get Started Now
              </Button>
            </BtnWrapper>
            <>
              <Table className='full-width'>
                <Column>
                  <div>Order number</div>
                  <div className='font-semibold'>{order?.id}</div>
                </Column>

                <Column>
                  <div>Date</div>
                  <div className='font-semibold'>
                    {formatDate(order?.created || '')}
                  </div>
                </Column>

                <Column>
                  <div>Email</div>
                  <div className='font-semibold'>{order?.customer?.email}</div>
                </Column>

                <Column>
                  <div>Total</div>
                  <div className='font-semibold'>
                    {formatMoney(order?.total)}
                  </div>
                </Column>

                <Column>
                  <div>Payment method</div>
                  <div className='font-semibold'>{order?.paymentTitle}</div>
                </Column>

                <Column>
                  <div>Status</div>
                  <div className='font-semibold'>{order?.status || '-'}</div>
                </Column>

                <Column>
                  <div>Payment status</div>
                  <div className='font-semibold'>
                    {order?.payment?.result || '-'}
                  </div>
                </Column>
              </Table>

              <div className='text-gray-600'>
                Thanks for purchasing a membership!
                <br />
                <br />
                View a status of your subscription in{' '}
                <Link className='text-black underline' to='/account'>
                  your account
                </Link>
                .
              </div>

              <div className='mt-[45px] bg-white p-5 md:p-10'>
                <OrderList order={order} />
              </div>

              {order?.subscriptions && order?.subscriptions?.length > 0 && (
                <div className='mt-[45px] bg-white p-5 md:p-10'>
                  <RelatedSubscriptions order={order} />
                </div>
              )}

              <div className='mt-[45px] bg-white p-5 md:p-10'>
                <Title className='text-left'>Billing information</Title>
                <Info className='text-gray-500 mb-[15px] flex gap-[5px] align-center'>
                  <EnvelopeIcon
                    className='mr-1 h-5 w-5 text-gray-300 mt-[2px]'
                    aria-hidden='true'
                  />
                  {order?.customer?.email}
                </Info>
                <div className='mb-[15px] text-gray-500 flex gap-[5px] align-center'>
                  <UserIcon
                    className='mr-1 h-5 w-5 text-gray-300 mt-[2px]'
                    aria-hidden='true'
                  />
                  {order?.customer?.firstName} {order?.customer?.lastName}
                </div>
                <div className='mb-[15px] text-gray-500 flex gap-[5px] align-center'>
                  <HomeIcon
                    className='mr-1 h-5 w-5 text-gray-300 mt-[2px]'
                    aria-hidden='true'
                  />
                  {order?.customer?.country}
                </div>
              </div>
            </>
          </>
        )}
      </div>
    </Layout>
  );
}

const Title = styled.div<{ $mb?: string }>`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: ${(props) => `${props.$mb || '24px'}`};
`;
const Info = styled.div`
  div {
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }
`;
const BtnWrapper = styled.div`
  @media screen and (max-width: 576px) {
    button {
      font-size: 12px;
      padding: 5px 10px;
      min-width: 150px;
    }
  }
`;
const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 45px;
  margin-bottom: 30px;
  gap: 30px;
  @media screen and (max-width: 991px) {
    gap: 20px;
  }
  @media screen and (max-width: 576px) {
    gap: 14px;
  }
`;
const Column = styled.div<{ $width?: string }>`
  min-width: ${(props) => `${props.$width || '100px'}`};
  @media screen and (max-width: 767px) {
    width: 50%;
    max-width: 200px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    font-size: 14px;
  }
`;
