import { useElements, useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { DropdownSelectOption } from 'Components/common/complex-select/ComplexSelect';
import Message from 'Components/common/message/Message';
import CartList from 'Components/features/cart/CartList';
import Address from 'Components/features/checkout/Adress';
import Payment from 'Components/features/checkout/Payment';
import Summary from 'Components/features/checkout/Summary';
import { useApi } from 'Contexts/ApiContext';
import { useCartContext } from 'Contexts/CartContext';
import React, { useEffect, useRef, useState } from 'react';
import 'typeface-poppins';
import 'typeface-roboto';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import secureIcon from '../../../assets/secure.svg';
import { CustomerData } from '../../../types/customer';
import { Gateways } from '../../../types/gateways.type';
import { Order } from '../../../types/order.type';

function Checkout({ gateways }: { gateways: Gateways }): JSX.Element {
  const { productItems, detailedItems, clearAll } = useCartContext();
  const api = useApi();
  const navigate = useNavigate();
  const elements = useElements();
  const stripe: any = useStripe();

  const [errorMessage, setErrorMessage] = useState('');
  const [orderMaking, setOrderMaking] = useState(false);
  const [orderStatus, setOrderStatus] = useState<string>('');

  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);

  const [selectedCountry, setSelectedCountry] = useState<DropdownSelectOption>({
    id: '',
    name: '',
  });
  const [paymentType, setPaymentType] = useState<'stripe' | 'paypal'>('stripe');
  const [btnText, setBtnText] = useState<string>('');

  function isValidEmail(email: string): boolean {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  const [customerData, setCustomerData] = useState<CustomerData>({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
  });

  const createOrder = async (): Promise<void> => {
    setOrderMaking(true);
    setErrorMessage('');

    // const firstName =
    //   firstNameRef.current instanceof HTMLInputElement
    //     ? firstNameRef.current.value
    //     : '';
    // const lastName =
    //   lastNameRef.current instanceof HTMLInputElement
    //     ? lastNameRef.current.value
    //     : '';
    // const email =
    //   emailRef.current instanceof HTMLInputElement
    //     ? emailRef.current.value
    //     : '';
    //
    // if (
    //   firstName === '' ||
    //   lastName === '' ||
    //   selectedCountry?.id === '' ||
    //   !isValidEmail(email) ||
    //   !productItems?.length
    // ) {
    //   setOrderMaking(false);
    //   setErrorMessage('Please enter correct required fields.');
    //   return;
    // }
    //
    // if (!elements || !stripe) {
    //   setOrderMaking(false);
    //   setErrorMessage('Please try again later.');
    //   return;
    // }
    //
    // const cardElement = elements && elements.getElement('cardNumber');
    //
    // const stripeResp: any = await stripe
    //   .createPaymentMethod({
    //     type: 'card',
    //     card: cardElement,
    //   })
    //   .catch((err: any) => {
    //     if (paymentType === 'stripe') {
    //       setOrderStatus(err?.response?.data?.message || 'Something wrong');
    //       console.log(err);
    //       setOrderMaking(false);
    //       return;
    //     }
    //   });
    //
    // if (
    //   stripeResp &&
    //   paymentType === 'stripe' &&
    //   !stripeResp?.paymentMethod?.id
    // ) {
    //   setOrderStatus('Invalid card data');
    //   setOrderMaking(false);
    //   return;
    // }
    //
    // const order: Order = {
    //   firstName: firstNameRef.current?.value || '',
    //   lastName: lastNameRef.current?.value || '',
    //   email: emailRef.current?.value || '',
    //   country: selectedCountry?.id,
    //   products: productItems.map((item) => ({
    //     id: Number(item.variationId) || Number(item.id),
    //     quantity: 1,
    //   })),
    //   paymentMethod: paymentType,
    //   token: stripeResp?.paymentMethod?.id || '',
    // };
    //
    // api
    //   .post('/wp-json/smart-piano-api/v1/orders', order)
    //   .then((response) => {
    //     setOrderStatus(response.statusText);
    //     console.log('Payment response', response);
    //     if (response?.data?.data?.paymentMethod === 'stripe') {
    //       clearAll();
    //       navigate(`/order-completed/${response?.data?.data?.id}`);
    //     } else {
    //       window.location.href = response?.data?.data?.payment?.redirect;
    //     }
    //   })
    //   .catch((err: AxiosError<any>) => {
    //     setOrderStatus(err?.response?.data?.message || 'Something wrong');
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setOrderMaking(false);
    //   });
  };

  useEffect(() => {
    if (
      detailedItems?.length &&
      detailedItems.find((item) => item.trialLength)
    ) {
      setBtnText('Start Free Trial');
    } else {
      setBtnText('Pay');
    }
  }, [detailedItems]);

  if (!productItems?.length) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {errorMessage && <Message color='#EF3E46'>{errorMessage}</Message>}

      <div className='px-[15px] md:px-0 flex lg:flex-row flex-col xs:gap-0 gap-[20px] m-auto'>
        <div className='flex flex-col max-w-full lg:w-[517px]'>
          <div className='mb-[28px]'>
            <Title>Order summary</Title>
            <CartList isReadOnly />
          </div>

          <div>
            <Title>Billing address</Title>
            <Address
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              firstNameRef={firstNameRef}
              lastNameRef={lastNameRef}
              emailRef={emailRef}
              customerData={customerData}
              setCustomerData={setCustomerData}
            />
          </div>

          <div className='mt-[13px]'>
            <Title className='justify-between'>
              <span className='content-end'>Payment Details </span>
              <SecureText>
                <span>Secured connection</span>
                <img src={secureIcon} alt='Secure' />
              </SecureText>
            </Title>
            <Payment
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              gateways={gateways}
            />
          </div>
        </div>

        <div className='xs:pl-[0px] w-full max-w-full lg:w-[383px] lg:max-w-[383px]'>
          <Summary
            orderMaking={orderMaking}
            createOrder={createOrder}
            btnText={btnText}
          />
          {orderStatus && (
            <div className='mt-[15px] ml-auto mr-auto text-center text-gray-400 max-w-[360px]'>
              {orderStatus}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const Title = styled.div<{ $marginBottom?: string }>`
  font-family: 'Poppins', 'Arial', sans-serif;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: ${(props): string =>
    props.$marginBottom ? props.$marginBottom : '16px'};
`;
const SecureText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #858585;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export default Checkout;
