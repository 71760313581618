import { useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useQuery } from 'react-query';

type Course = {
  courseUrl: string;
  imageUrl: string;
  post_id: number;
  title: string;
};

export type CourseData = {
  courses: Course[];
  courseCount: number;
};

type CourseResponse = {
  courseData: CourseData;
  isFetching: boolean;
};

export const useGetCourses = (
  search: string,
  categoryId: number,
  courseType: string,
  limit: number,
  offset: number
): CourseResponse => {
  const api = useUnauthenticatedApi();

  const fetchCourses = async (): Promise<CourseData> =>
    (
      await api.post('/api/v1/courses.php', {
        categoryId: categoryId,
        courseType,
        limit,
        offset,
        search,
      })
    ).data;

  const { data: courseData, isFetching } = useQuery(
    ['courses', categoryId, courseType, limit, offset, search],
    fetchCourses,
    {
      enabled: true,
      onError: (error) => console.error(error),
    }
  );
  return {
    courseData: courseData || { courses: [], courseCount: 0 },
    isFetching,
  };
};

export const useGetAllCourses = (): CourseResponse => {
  const api = useUnauthenticatedApi();

  const fetchAllCourses = async (): Promise<CourseData> =>
    (
      await api.post('/api/v1/courses.php', {
        categoryId: -1,
      })
    ).data;

  const { data: courseData, isFetching } = useQuery(
    ['courses'],
    fetchAllCourses,
    {
      enabled: true,
      onError: (error) => console.error(error),
    }
  );
  return {
    courseData: courseData || { courses: [], courseCount: 0 },
    isFetching,
  };
};
