import { useQuery } from 'react-query';
import { useUnauthenticatedApi } from '../contexts/ApiContext';

export type Category = {
  name: string;
  slug: string;
  count: number;
  term_id: number;
};

type CategoryResponse = {
  categories: Category[];
  isFetching: boolean;
};

export const useGetCategories = (): CategoryResponse => {
  const api = useUnauthenticatedApi();
  const fetchCategories = async (): Promise<Category[]> =>
    (await api.get('/api/v1/categories.php')).data;
  const { data: categories, isFetching } = useQuery(
    ['categories'],
    fetchCategories,
    {
      enabled: true,
      onError: (error) => console.error(error),
    }
  );
  return { categories: categories || [], isFetching };
};
