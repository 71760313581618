import {
  ArrowRightOnRectangleIcon,
  BellIcon,
  IdentificationIcon,
  InboxIcon,
  UserIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import { UserContext } from 'Contexts/UserContext';
import React, { useContext } from 'react';
import Header from './Header';

export default function LoggedInHeader(): JSX.Element {
  const apiUrl = process.env.API_URL;
  const { user } = useContext(UserContext);

  const userMenuOptions = [
    {
      name: 'Profile',
      icon: (
        <UserIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: `${apiUrl}/members/${user?.username}/profile`,
    },
    {
      name: 'Account Details',
      icon: (
        <IdentificationIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: `${apiUrl}/account`,
    },
    {
      name: 'Notifications',
      icon: (
        <BellIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: `${apiUrl}/members/${user?.username}/notifications`,
    },
    {
      name: 'Messages',
      icon: (
        <InboxIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: `${apiUrl}/members/${user?.username}/messages`,
    },
    {
      name: 'Checkout',
      icon: (
        <ShoppingCartIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: '/checkout',
    },
    {
      name: 'Logout',
      icon: (
        <ArrowRightOnRectangleIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: '/login?action=logout',
    },
  ];
  const headers = [
    {
      title: 'Dashboard',
      url: `${apiUrl}/dashboard/`,
    },
    {
      title: 'Song Library',
      url: '/',
    },
    {
      title: 'Favorite Songs',
      url: `${apiUrl}/favorite-lessons/`,
    },
    {
      title: 'Courses',
      url: '/course-library',
    },
    {
      title: 'Community',
      url: `${apiUrl}/community/`,
    },
    {
      title: 'Help',
      url: `${apiUrl}/help/`,
    },
  ];

  return (
    <Header
      gravatarUrl={user?.gravatarUrl}
      headers={headers}
      userMenuOptions={userMenuOptions}
    />
  );
}
