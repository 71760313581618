import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { UserContext } from 'Contexts/UserContext';
import React, { useContext } from 'react';
import Header from './Header';

export default function LoggedOutHeader(): JSX.Element {
  const apiUrl = process.env.API_URL;
  const { user } = useContext(UserContext);
  const userMenuOptions = [
    {
      name: 'Login',
      icon: (
        <ArrowLeftOnRectangleIcon
          className='h-4 w-4 mr-3 group-hover:text-primary-500'
          aria-hidden='true'
        />
      ),
      link: '/login',
    },
  ];
  const headers = [
    {
      title: 'Song Library',
      url: '/',
    },
    {
      title: 'Courses',
      url: '/course-library',
    },
    {
      title: 'Help',
      url: `${apiUrl}/help/`,
    },
  ];

  return (
    <Header
      gravatarUrl={user?.gravatarUrl}
      headers={headers}
      userMenuOptions={userMenuOptions}
      hideInbox={true}
      hideNotifications={true}
      hideShoppingCart={true}
    />
  );
}
