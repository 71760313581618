import { useApi } from 'Contexts/ApiContext';
import { DetailedProduct } from 'Contexts/CartContext';
import { useMemo } from 'react';
import { useQueries, useQuery, UseQueryResult } from 'react-query';
import { CartProduct, StorageProduct } from '../../types/cart.product';
import { PRODUCT_TYPE } from '../helpers/subscription.helpers';

type productsResponse = {
  productsList?: CartProduct[] | any;
  subscriptionsList?: CartProduct[] | any;
  isFetching: boolean;
};

type ProductsItemsByIdsQuery = [CartProduct[] | any, boolean];

export const useGetSubscriptions = (): productsResponse => {
  const api = useApi();
  const token = localStorage.getItem('token');

  const fetchProducts = async (): Promise<any> =>
    (await api.get('/wp-json/smart-piano-api/v1/products/?type=subscription'))
      .data;

  const placeholderData = useMemo(() => {
    return [];
  }, []);

  const { data: subscriptionsList, isFetching } = useQuery(
    ['subscriptionsList'],
    fetchProducts,
    {
      onError: (error) => console.error(error),
      placeholderData,
      enabled: !!token,
    }
  );

  return <productsResponse>useMemo(() => {
    return !subscriptionsList
      ? {}
      : {
          subscriptionsList: subscriptionsList?.data?.products || [],
          isFetching,
        };
  }, [isFetching, subscriptionsList]);
};

export const useGetProducts = (): productsResponse => {
  const api = useApi();
  const token = localStorage.getItem('token');

  const fetchProducts = async (): Promise<any> =>
    (await api.get('/wp-json/smart-piano-api/v1/products')).data;

  const placeholderData = useMemo(() => {
    return [];
  }, []);

  const { data: productsList, isFetching } = useQuery(
    ['productsList'],
    fetchProducts,
    {
      onError: (error) => console.error(error),
      placeholderData,
      enabled: !!token,
    }
  );

  return <productsResponse>useMemo(() => {
    return !productsList
      ? {}
      : { productsList: productsList?.data?.products || [], isFetching };
  }, [isFetching, productsList]);
};

export const useGetProductsByStorage = (
  ids: { id: number; variationId: number | null; type: string }[]
): ProductsItemsByIdsQuery => {
  const api = useApi();
  const items: any[] = [];
  const isFetcheds: any[] = [];
  const token = localStorage.getItem('token');

  const fetchProduct = async ({
    id,
    type,
  }: {
    id: number;
    variationId: number | null;
    type: string;
  }): Promise<DetailedProduct[]> => {
    if (type === PRODUCT_TYPE.simpleProduct || PRODUCT_TYPE.variableProduct) {
      let result = (
        await api.get(`/wp-json/smart-piano-api/v1/products/?include=${id}`)
      ).data;
      result = result?.data?.products?.[0];
      return result;
    }
    let result = (
      await api.get(
        `/wp-json/smart-piano-api/v1/products/?type=subscription&include=${id}`
      )
    ).data;
    result = result?.data?.products?.[0];

    return result;
  };

  const placeholderData = useMemo(() => {
    return {};
  }, []);

  const responses: Array<UseQueryResult> = useQueries<
    Array<UseQueryResult<StorageProduct[], Error>>
  >(
    ids.map(({ id, variationId, type }) => {
      return {
        queryKey: ['productItems', id],
        queryFn: () => fetchProduct({ id, variationId, type }),
        onError: (error) => console.error(error),
        placeholderData,
        enabled: !!token,
      };
    })
  );

  responses.forEach((el: UseQueryResult) => {
    isFetcheds.push(el.isFetching);
    items.push(el?.data || {});
  });

  const isFetching = !!responses.find((el) => el.isFetching);

  return [items, isFetching];
};

export const useGetCountries = (): any => {
  const api = useApi();
  const token = localStorage.getItem('token');

  const fetchProducts = async (): Promise<any> =>
    (await api.get('/wp-json/smart-piano-api/v1/countries')).data;

  const placeholderData = useMemo(() => {
    return [];
  }, []);

  const { data: countries, isFetching } = useQuery(
    ['countries'],
    fetchProducts,
    {
      onError: (error) => console.error(error),
      placeholderData,
      enabled: !!token,
    }
  );

  return { countries: countries?.data || [], isFetching };
};

export const useGetPaymentMethods = (): any => {
  const api = useApi();
  const token = localStorage.getItem('token');

  const fetchProducts = async (): Promise<any> =>
    (await api.get('/wp-json/smart-piano-api/v1/payment-gateways')).data;

  const placeholderData = useMemo(() => {
    return [];
  }, []);

  const { data: gateways, isFetching } = useQuery(
    ['payment-gateways'],
    fetchProducts,
    {
      onError: (error) => console.error(error),
      placeholderData,
      enabled: !!token,
    }
  );

  return { gateways: gateways?.data || [], isFetching };
};
