export enum PRODUCT_TYPE {
  subscription = 'subscription', // Simple subscription
  variableSubscription = 'variable-subscription', // Variable subscription
  simpleProduct = 'simple', // Simple product
  variableProduct = 'variable', // Variable product
  productVariation = 'variation', // Product variation
  subscriptionVariation = 'subscription_variation', // child variant of subscription item
  // No items: Grouped product, External/Affiliate product
}

export const defaultImage =
  'https://d10heettfeutgf.cloudfront.net/uploads/2021/06/Kickstart-Membership.png';

export const defaultSelectVariant = {
  id: 'NO',
  name: 'Choose an option',
};

export const isSubscription = (item: any): boolean => {
  return (
    item?.type === PRODUCT_TYPE.subscription ||
    item?.type === PRODUCT_TYPE.variableSubscription
  );
};

export const findSubscriptionInCart = (
  detailedItems: any,
  productItems: any
): boolean => {
  return (
    detailedItems.find(
      (item: any) =>
        item?.type === PRODUCT_TYPE.subscription ||
        item?.type === PRODUCT_TYPE.variableSubscription ||
        item?.parent
    ) || productItems.find((item: any) => item?.parent)
  );
};

// export const hasParent = (item: any): boolean => {
//   return !!item?.parent;
// };
// export const isVariation = (item: any): boolean => {
//   return !item?.parent;
// };
