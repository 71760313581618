import React from 'react';
import 'typeface-poppins';
import 'typeface-roboto';
import styled from 'styled-components';

type InputParams = {
  id: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUpKey?: string;
  placeholder?: string;
  type?: string;

  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  autoComplete?: string;
  label: string;
  isError?: boolean;
  errorText?: string;
};

const ComplexInput = ({
  id,
  inputRef,
  name,
  onKeyUp = (): void => void 0,
  onKeyUpKey = 'Enter',
  placeholder,
  type = 'text',
  value,
  onChange,
  required,
  autoComplete,
  label = '',
  isError = false,
  errorText = '',
}: InputParams): JSX.Element => {
  function executeKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (onKeyUp) {
      if (event.key === onKeyUpKey) {
        onKeyUp(event);
      }
    }
  }
  return (
    <div className='col-span-6 sm:col-span-3'>
      <label>
        <LabelText>
          {label} {required && '*'}
        </LabelText>
        <input
          style={{ borderRadius: '2px' }}
          className={`
          ${isError ? 'border-red-500' : 'border-gray-300'}
          py-[10px] px-[13px] mt-1 block w-full shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm`}
          id={id}
          name={name}
          onKeyUp={executeKeyUp}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          value={value}
          onChange={onChange}
          required={required}
          autoComplete={autoComplete}
        />
        {isError && <ErrorText className='text-red-500'>{errorText}</ErrorText>}
      </label>
    </div>
  );
};

const LabelText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #707070;
`;
const ErrorText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-top: 2px;
`;

export default ComplexInput;
