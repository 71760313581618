import React from 'react';
import 'typeface-poppins';

type ProgressProps = {
  completionPercentage: number;
};

function Progress({ completionPercentage }: ProgressProps): JSX.Element {
  const circumference = ((2 * 22) / 7) * 45;
  const offset = circumference - (completionPercentage / 100) * circumference;
  return (
    <div className='flex items-center justify-center bg-transparent'>
      <svg className='transform -rotate-90 w-36 h-36'>
        <circle
          cx='50'
          cy='70'
          r='45'
          stroke='currentColor'
          strokeWidth='3'
          fill='transparent'
          className='text-white'
        />

        <circle
          cx='50'
          cy='70'
          r='45'
          stroke='currentColor'
          strokeWidth='3'
          fill='transparent'
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          className='text-green-500'
        />
      </svg>
      <span className='absolute text-xl font-medium font-poppins lg:text-white'>
        {completionPercentage}%
        <div className='font-medium text-xs font-poppins'>Complete</div>
      </span>
    </div>
  );
}

export default Progress;
