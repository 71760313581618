import ComplexInput from 'Components/common/complex-input/ComplexInput';
import { DropdownSelectOption } from 'Components/common/complex-select/ComplexSelect';
import ComplexSelect from 'Components/common/complex-select/ComplexSelect';
import DotLoader from 'Components/common/dot-loader/DotLoader';
import { useGetCountries } from 'Hooks/useGetProducts';
import { ChangeEvent, RefObject, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CustomerData } from '../../../types/customer';
import 'typeface-poppins';
import 'typeface-roboto';

type AdressProps = {
  selectedCountry: DropdownSelectOption;
  setSelectedCountry: (item: DropdownSelectOption) => void;
  firstNameRef: RefObject<HTMLInputElement>;
  lastNameRef: RefObject<HTMLInputElement>;
  emailRef: RefObject<HTMLInputElement>;
  customerData: CustomerData;
  setCustomerData: (value: CustomerData) => void;
};

function Address({
  setSelectedCountry,
  firstNameRef,
  lastNameRef,
  emailRef,
  customerData,
  setCustomerData,
}: AdressProps): JSX.Element {
  const { countries, isFetching } = useGetCountries();
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isCountryError, setIsCountryError] = useState(false);

  function isValidEmail(email: string): boolean {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  const countryOptions = useMemo(() => {
    if (!countries) {
      return [];
    }
    const arr: DropdownSelectOption[] = [];
    for (const key in countries) {
      arr.push({ id: key, name: countries?.[key] });
    }
    return arr;
  }, [countries]);

  function countrySelected(selectedItem: DropdownSelectOption): void {
    if (selectedItem?.id && selectedItem?.name) {
      setSelectedCountry(selectedItem);
      setCustomerData({ ...customerData, country: selectedItem.id });
      setIsCountryError(!selectedItem.id);
    }
  }

  function changeFirstName(e: ChangeEvent<HTMLInputElement>): void {
    setCustomerData({ ...customerData, firstName: e.target.value });
    setIsFirstNameError(!(e.target.value.length > 2));
  }

  function changeLastName(e: ChangeEvent<HTMLInputElement>): void {
    setCustomerData({ ...customerData, lastName: e.target.value });
    setIsLastNameError(!(e.target.value.length > 2));
  }

  function changeEmail(e: ChangeEvent<HTMLInputElement>): void {
    setCustomerData({ ...customerData, email: e.target.value });
    setEmailError(!isValidEmail(e.target.value));
  }

  return (
    <>
      <ContainerTwoCol>
        <InputContainer>
          <ComplexInput
            isError={isFirstNameError}
            errorText='Invalid first name'
            id='firstName'
            name='firstName'
            placeholder='Your first name'
            inputRef={firstNameRef}
            required
            autoComplete='off'
            label='First name'
            onChange={changeFirstName}
          />
        </InputContainer>
        <InputContainer>
          <ComplexInput
            isError={isLastNameError}
            errorText='Invalid last name'
            id='lastName'
            name='lastName'
            placeholder='Your last name'
            inputRef={lastNameRef}
            autoComplete='off'
            label='Last name'
            required
            onChange={changeLastName}
          />
        </InputContainer>
      </ContainerTwoCol>
      <InputContainer>
        <ComplexSelect
          isError={isCountryError}
          errorText='The country is not selected'
          label='Country'
          required
          onChange={countrySelected}
          options={
            (countryOptions?.length &&
              countryOptions?.map((country: { id: string; name: string }) => ({
                id: country.id,
                name: country.name,
              }))) ||
            []
          }
        />
        {isFetching && (
          <div className='flex flex-1 justify-center items-center mt-3.5'>
            <DotLoader type='small' />
          </div>
        )}
      </InputContainer>
      <InputContainer>
        <ComplexInput
          isError={isEmailError}
          errorText='Invalid email format'
          id='email'
          name='email'
          required
          placeholder='test@gmail.com'
          inputRef={emailRef}
          autoComplete='off'
          label='Email'
          onChange={changeEmail}
        />
      </InputContainer>
    </>
  );
}

const InputContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
`;

const ContainerTwoCol = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

export default Address;
