import React from 'react';
import Message from '../../common/message/Message';
import PrivacyPolicy from '../../features/privacy-policy/PrivacyPolicy';
import LoginHeader from '../login-header/LoginHeader';

function CheckEmail(): JSX.Element {
  return (
    <>
      <LoginHeader />
      <Message>
        Check your email for the confirmation link, then visit the{' '}
        <a href='/login'>login page</a>.
      </Message>
      <PrivacyPolicy />
    </>
  );
}

export default CheckEmail;
