import React from 'react';
import LoggedInHeader from './LoggedInHeader';
import LoggedOutHeader from './LoggedOutHeader';

export default function MainHeader(): JSX.Element {
  const isLoggedIn = !!localStorage.getItem('token');

  function getHeader(loggedIn: boolean): JSX.Element {
    if (loggedIn) {
      return <LoggedInHeader />;
    } else {
      return <LoggedOutHeader />;
    }
  }

  return <>{getHeader(isLoggedIn)}</>;
}
