import { useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export type EnrolledUser = {
  firstName: string;
  id: number;
  nicename: string;
  gravatarUrl: string;
};

export type CourseDetail = {
  category: string;
  categoryId: number;
  courseContent: string;
  courseId: number;
  courseImageUrl: string;
  courseSlug: string;
  courseStepCount: number;
  courseTitle: string;
  lessonDetails: LessonDetail[];
  lessonIds: number[];
  usersEnrolled: EnrolledUser[];
};

type CourseDetailResponse = {
  courseDetailData: CourseDetail | any;
  isFetching: boolean;
  refetch: any;
};

export type LessonDetail = {
  id: number;
  lessonSlug: string;
  lessonTitle: string;
  lessonUrl: string;
};

export const useGetCourseDetail = (postName: string): CourseDetailResponse => {
  const api = useUnauthenticatedApi();
  const fetchCourseDetail = async (): Promise<CourseDetail> =>
    (
      await api.post('/api/v1/course-detail.php', {
        postName,
      })
    ).data;
  const placeholderData = useMemo(() => {
    return {};
  }, []);
  const {
    data: courseDetailData,
    isFetching,
    refetch,
  } = useQuery(['courseDetail', postName], fetchCourseDetail, {
    enabled: true,
    onError: (error) => console.error(error),
    placeholderData: placeholderData,
  });
  return { courseDetailData, isFetching, refetch };
};
