import { useApi } from 'Contexts/ApiContext';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type UserActiveCourse = {
  courseId: number;
  courseImageUrl: string;
  courseTitle: string;
  courseLessonCount: number;
  courseLessonCompletedCount: number;
  lessonUrl: string;
  lessonTitle: string;
};

type UserActiveCourseResponse = {
  userActiveCourses: UserActiveCourse[] | undefined;
  isFetching: boolean;
};

export const useGetUserActiveCourses = (): UserActiveCourseResponse => {
  const api = useApi();
  const fetchUserActiveCourses = async (): Promise<UserActiveCourse[]> =>
    (await api.get('/api/v1/user-active-courses.php')).data;
  const placeholderData = useMemo(() => {
    return [];
  }, []);
  const { data: userActiveCourses, isFetching } = useQuery(
    ['userActiveCourses'],
    fetchUserActiveCourses,
    {
      onError: (error) => console.error(error),
      placeholderData,
    }
  );
  return { userActiveCourses, isFetching };
};
