import Button from 'Components/common/button/Button';
import { useCartContext } from 'Contexts/CartContext';
import styled from 'styled-components';
import { formatMoney } from 'Utils/formatMoney';
import couponIcon from '../../../assets/coupon.svg';
import 'typeface-poppins';
import 'typeface-roboto';

function Summary({
  orderMaking,
  createOrder,
  btnText,
}: {
  orderMaking: boolean;
  createOrder: () => Promise<void>;
  btnText: string;
}): JSX.Element {
  const { productItems, getPurchaseAmount } = useCartContext();

  const subtotal = getPurchaseAmount();
  const tax = 0;
  const dueToday = getPurchaseAmount();

  return (
    <div className='max-w-1 my-0 mx-auto mt-[32px] lg:mt-[46px] m-auto'>
      <CouponeCode>
        <img src={couponIcon} alt='Coupon' />
        Coupon code
      </CouponeCode>
      <SummaryWrapper>
        {productItems?.length > 0 && (
          <SummaryBlock>
            <>
              <Subtotal className={'flex justify-between'}>
                <div>Subtotal:</div>
                <div>{formatMoney(subtotal)}</div>
              </Subtotal>
              <Subtotal className={'flex justify-between'}>
                <div>Tax:</div>
                <div>{formatMoney(tax)}</div>
              </Subtotal>
              <hr className='mt-[8px] mb-[4px]' />
              <Total className={'flex justify-between'}>
                <div>Due Today:</div>
                <div>{formatMoney(dueToday)}</div>
              </Total>
              <br />
              <NoticeGray>
                By completing your order you agree to our{' '}
                <a href='' className='text-[#25AD61]'>
                  {' '}
                  Terms of Service
                </a>
                , Privacy Statement.
              </NoticeGray>

              <div className='flex justify-center'>
                <Button
                  width='w-full'
                  flex={true}
                  loading={orderMaking}
                  fontWeight='think'
                  backgroundColor='bg-green-600'
                  onClick={createOrder}
                >
                  {btnText}
                </Button>
              </div>
              <Notice>
                Your credit card will not be charged for this transaction.
              </Notice>
            </>
          </SummaryBlock>
        )}
      </SummaryWrapper>
    </div>
  );
}

const Subtotal = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding: 2px 16px;
`;
const Total = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 2px 16px;
`;
const Notice = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #3874db;
  margin-top: 14px;
  padding: 2px 16px;
`;
const NoticeGray = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #707070;
  padding: 2px 16px;
  span {
    font-weight: 600;
  }
`;
const SummaryWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(23, 23, 23, 0.11);
  border-radius: 9px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const SummaryBlock = styled.div`
  padding: 24px 21px 18px;
  width: 383px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  margin: 0 auto;
`;
const CouponeCode = styled.div`
  background: #bdffda;
  border: 1px solid #25ad61;
  border-radius: 2px;
  height: 48px;
  margin-bottom: 20px;
  padding: 14px 16px;
  img {
    margin-right: 8px;
  }
  display: flex;
  font-family: 'Poppins', Arial, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #72bd93;
`;

export default Summary;
