import { ChevronRightIcon } from '@heroicons/react/24/solid';
import Button from 'Components/common/button/Button';
import Progress from 'Components/common/progress/Progress';
import Tooltip from 'Components/common/tooltip/Tooltip';
import Layout from 'Components/ui/layouts/Layout';
import { UserContext } from 'Contexts/UserContext';
import { useGetUserActiveCourses } from 'Hooks/useGetUserActiveCourses';
import React, { useContext } from 'react';
import 'typeface-poppins';
import 'typeface-roboto';

function Dashboard(): JSX.Element {
  const apiUrl = process.env.API_URL;
  const { userActiveCourses, isFetching } = useGetUserActiveCourses();
  console.log(userActiveCourses);
  console.log(isFetching);
  const { user } = useContext(UserContext);
  return (
    <Layout>
      <div className='bg-cover w-screen p-[20px] lg:h-[250px] h-[454px] overflow-hidden bg-no-repeat bg-center bg-[url("https://d10heettfeutgf.cloudfront.net/uploads/2021/04/Pixelgraphic@3x_2.png")]'>
        <div className='flex flex-col'>
          <div className='grid lg:grid-cols-6 lg:gap-[15px] place-content-center  w-full lg:self-center lg:w-[1200px]'>
            <div className='grid lg:col-start-1 lg:justify-end items-center justify-center w-full lg:mt-0 mt-[20px] lg:pr-[25px] mb-[10px]'>
              <img
                className='max-w-[115px] w-[115px] shadow-black/30 shadow-md border-inherit rounded-lg border-solid p-1.5'
                src={user?.gravatarUrl}
              />
            </div>
            <div className='grid lg:col-start-2 lg:col-span-2 lg:justify-start items-center w-full text-center lg:text-left '>
              <div className='text-white font-poppins text-4xl grid'>
                Welcome, {user?.firstName}
                <div className='h-full text-white text-2xl font-poppins grid'>
                  <div>to your Smartist Dashboard</div>
                </div>
              </div>
            </div>
            <div className='grid lg:col-start-6 lg:justify-start items-center justify-center w-full pt-[20px]'>
              <div className='pl-[30px] pb-[10px]'>
                <div className='h-[100px] w-[100px]'>
                  <Progress completionPercentage={60} />
                </div>
              </div>
              <div className='mt-[20px] z-10 '>
                <a
                  className='no-underline visited:no-underline'
                  href={`${apiUrl}/members/${user?.username}/profile/edit/`}
                >
                  <Button
                    backgroundColor='lg:bg-transparent bg-blue-500'
                    borderColor='lg:border-white'
                    borderRadius='rounded-full'
                    fontWeight='font-small'
                    hover='hover:text-black'
                    size='small'
                  >
                    Complete Profile{' '}
                    <ChevronRightIcon
                      className='h-4 w-4 mt-1 ml-2'
                      aria-hidden='true'
                    />
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='lg:mt-[50px] lg:mr-[965px] lg:pr-[40px] font-roboto font-medium text-dashboardBlue-color text-2xl lg:text-start lg:place-content-center flex mt-[20px]'>
          Quick links
        </div>
        <div className='pt-[35px] lg:w-[1200px] lg:pl-[30px] grid lg:grid-cols-4 place-content-center lg:mx-[260px] justify-items-center items-center lg:self-center'>
          <a href='https://smartgamepiano.com/courses/?current_page=1&search=&type=my-courses'>
            <div className='group relative grid justify-items-center box-content h-[194px] lg:w-[268px] w-screen bg-neutral-50 rounded-lg hover:shadow-xl hover:rounded-none mb-[8px] lg:mb-0'>
              <Tooltip message='Courses and song tutorials that you enrolled in or started.' />
              <img
                src='https://d10heettfeutgf.cloudfront.net/uploads/2021/04/SGP-Icons_Explore.svg'
                className='h-[77px] w-[77px mt-[30px]'
              />
              <div className='break-inside-avoid-column grid text-center font-poppins text-dashboardBlue-color text-xl mb-[15px] hover:text-blue-500'>
                {' '}
                My Lessons
              </div>
            </div>
          </a>
          <a href='https://smartgamepiano.com/favorite-lessons'>
            <div className='group relative grid justify-items-center box-content h-[194px] lg:w-[268px] w-screen bg-neutral-50 rounded-lg hover:shadow-xl hover:rounded-none mb-[8px] lg:mb-0'>
              <Tooltip message='All your favorite tutotorials in one place.' />
              <img
                src='https://d10heettfeutgf.cloudfront.net/uploads/2021/04/SGP-Icons_Favourite.svg'
                className='h-[77px] w-[77px] mt-[30px]'
              />
              <div className='break-inside-avoid-column text-center font-poppins text-dashboardBlue-color text-xl mb-[15px] hover:text-blue-500'>
                {' '}
                My Favorites
              </div>
            </div>
          </a>
          <a href='https://www.musicnotes.com/sheet-music/artist/smart-game-piano'>
            <div className='group relative grid justify-items-center box-content h-[194px] lg:w-[268px] w-screen bg-neutral-50 rounded-lg hover:shadow-xl hover:rounded-none mb-[8px] lg:mb-0'>
              <Tooltip message='Get 30% off sheet music using the code below.' />
              <img
                src='https://d10heettfeutgf.cloudfront.net/uploads/2022/03/13202658/SGP-Icons_Sheet-Blue-1.svg'
                className='h-[77px] w-[77px] mt-[30px]'
              />
              <div className='break-inside-avoid-column text-center font-poppins text-dashboardBlue-color text-xl mb-[15px] hover:text-blue-500'>
                {' '}
                BWA8QYVX6V - 30%
              </div>
            </div>
          </a>
          <a href='https://smartgamepiano.com/your-ranks/'>
            <div className='group relative grid justify-items-center box-content h-[194px] lg:w-[268px] w-screen bg-neutral-50 rounded-lg hover:shadow-xl hover:rounded-none'>
              <Tooltip message='Discover your achievments.' />
              <img
                src='https://d10heettfeutgf.cloudfront.net/uploads/2021/04/SGP-Icons_Community.svg'
                className='h-[77px] w-[77px] mt-[30px]'
              />
              <div className='break-inside-avoid-column text-center font-poppins text-dashboardBlue-color text-xl mb-[15px] hover:text-blue-500'>
                {' '}
                Your Achievments
              </div>
            </div>
          </a>
        </div>
        <div className='mt-[75px] lg:w-[1200px] lg:pl-[30px] grid lg:grid-cols-3 grid-cols-1 lg:self-center place-content-center lg:mx-[275px] justify-items-start items-center'>
          <div className='grid text-2xl lg:w-[770px] w-full font-roboto font-medium text-dashboardBlue-color lg:col-span-2'>
            Pick up where you left off
          </div>
          <div className='mt-[25px] lg:mt-0 grid text-2xl lg:w-[378px] w-full font-roboto font-medium text-dashboardBlue-color lg:col-start-3 row-start-3 lg:row-start-auto'>
            Latest tutorials
          </div>
          <div className='grid justify-items-center content-center mt-[30px] box-content h-[391px] lg:w-[760px] w-screen bg-neutral-50 rounded-lg shadow-xl lg:col-span-2'>
            {' '}
            <img
              src='https://smartgamepiano.com/wp-content/themes/buddyboss-theme/assets/images/svg/dfy-no-data-icon04.svg'
              className='h-[105px] w-[101px] mb-[30px]'
            />
            You don&apos;t have any ongoing courses.
            <Button
              backgroundColor='lg:bg-transparent'
              borderColor='lg:border-gray'
              borderRadius='rounded-full'
              fontWeight='font-small'
              hover='hover:text-green-500'
              size='small'
              textColor='black'
            >
              Explore Courses
            </Button>
          </div>
          <div className='mt-[30px] box-content h-[391px] lg:w-[380px] w-screen bg-neutral-50 rounded-lg shadow-xl'>
            {' '}
            Need more stuff{' '}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
