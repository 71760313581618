import React from 'react';
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from 'react-icons/fa';
import styled from 'styled-components';

function Footer(): JSX.Element {
  return (
    <FooterContainer>
      <Copyright>© 2022 - Smart Game Piano</Copyright>
      <SocialIcons>
        <SocialLink href='https://www.facebook.com/smartgamepiano'>
          <FaFacebookSquare size={24} />
        </SocialLink>
        <SocialLink href='https://www.instagram.com/smartgamepiano/'>
          <FaInstagramSquare size={24} />
        </SocialLink>
        <SocialLink href='https://www.youtube.com/c/SmartGamePiano1'>
          <FaYoutubeSquare size={24} />
        </SocialLink>
      </SocialIcons>
    </FooterContainer>
  );
}

const Copyright = styled.div`
  color: black;
  font-size: 13px;
  font-weight: 700;
`;

const FooterContainer = styled.div`
  background-color: #2b2b2b;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 30px;
  width: auto;
`;

const SocialIcons = styled.div`
  display: flex;
  & > a {
    padding-left: 15px;
  }
`;

const SocialLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

export default Footer;
