import { ApiProvider } from 'Contexts/ApiContext';
import { CartProvider } from 'Contexts/CartContext';
import { UserProvider } from 'Contexts/UserContext';
import Course from 'Pages/Course';
import CourseLibrary from 'Pages/CourseLibrary';
import DashboardPage from 'Pages/Dashboard';
import ErrorPage from 'Pages/Error';
import LoginPage from 'Pages/Login';
import OrderPage from 'Pages/Order';
import SongLibrary from 'Pages/SongLibrary';
import { StripeCheckout } from 'Pages/StripeCheckout';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

const staleTime = 60 * 1000 * 5; // Five minutes in milliseconds
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: staleTime,
    },
  },
});

const isLoggedIn = localStorage.getItem('token') !== null;

const router = createBrowserRouter([
  {
    path: '/',
    element: <SongLibrary />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/courses/:post_name',
    element: <Course />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/course-library',
    element: <CourseLibrary />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/Dashboard',
    element: <DashboardPage />,
  },
  {
    path: '/order-completed',
    children: [
      {
        path: ':id',
        element: <OrderPage />,
      },
    ],
  },
  {
    path: '/checkout',
    element: isLoggedIn ? <StripeCheckout /> : <Navigate to={'/login'} />,
  },
]);

const rootElement = document.getElementById('root');
rootElement instanceof HTMLElement &&
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <CartProvider>
              <RouterProvider router={router} />
            </CartProvider>
          </UserProvider>
        </QueryClientProvider>
      </ApiProvider>
    </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
