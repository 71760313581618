import React from 'react';
import styled from 'styled-components';

type LinkProps = {
  children: React.ReactNode;
  href: string;
};

function Link({ children, href }: LinkProps): JSX.Element {
  return <StyledLink href={href}>{children}</StyledLink>;
}

const StyledLink = styled.a`
  color: rgb(39, 174, 96);
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
`;

export default Link;
