import { StripeForm } from 'Components/features/checkout/StripeForm';
import React, { useState } from 'react';
import 'typeface-poppins';
import 'typeface-roboto';
import styled from 'styled-components';
import cardIcon from '../../../assets/card.svg';
import mastercardIcon from '../../../assets/mastercard.svg';
import paypalIcon from '../../../assets/paypal.svg';
import visaIcon from '../../../assets/visa.svg';

type PaymentProps = {
  paymentType: string;
  setPaymentType: (value: 'stripe' | 'paypal') => void;
  gateways: any;
};

function Payment({
  paymentType,
  setPaymentType,
  gateways,
}: PaymentProps): JSX.Element {
  const [isAgree, setIsAgree] = useState(false);

  return (
    <>
      <div>
        <LabelText>Payment Method</LabelText>

        <PaymentLabel onClick={(): void => setPaymentType('stripe')}>
          <div className='flex w-full justify-between items-center'>
            <div className='flex items-center'>
              <input
                onChange={(e): void => e.stopPropagation()}
                checked={paymentType === 'stripe'}
                type='radio'
                name='payment'
              />
              <img className='mr-[8px]' src={cardIcon} alt='Card' />
              {gateways?.stripe?.title || 'Credit card'}
            </div>
            <div className='flex items-center'>
              <img className='mr-[8px]' src={visaIcon} alt='Visa' />
              <img className='mr-0' src={mastercardIcon} alt='Visa' />
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: gateways?.stripe?.icon,
            }}
          />
        </PaymentLabel>

        {paymentType === 'stripe' && (
          <Container>
            <StripeForm />

            <CheckboxLabel className='mt-[5px]'>
              <input
                className='green'
                type='checkbox'
                onChange={(): void => setIsAgree(!isAgree)}
                defaultChecked={isAgree}
              />
              Save this card for future payment.
            </CheckboxLabel>
          </Container>
        )}
      </div>

      <div>
        <PaymentLabel
          className='mt-3'
          onClick={(): void => setPaymentType('paypal')}
        >
          <div>
            <input
              onChange={(e): void => e.stopPropagation()}
              checked={paymentType === 'paypal'}
              type='radio'
              name='payment'
            />
            <img className='mr-[8px]' src={paypalIcon} alt='PayPal' />
            {gateways?.paypal?.title || 'PayPal'}
          </div>
        </PaymentLabel>
      </div>
    </>
  );
}

const PaymentLabel = styled.div`
  background: #e2e2e2;
  border: 1px solid #b9b9b9;
  border-radius: 4px 4px 0px 0px;
  padding: 0 8px;
  display: flex;
  min-height: 47px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  & > div {
    display: flex;
    align-items: center;
  }
  :hover {
    cursor: pointer;
  }
  input {
    margin-right: 10px;
    &.green {
      color: green;
    }
    color: #262626;
    &.green {
      border: 1px solid green;
    }
    border: 1px solid #262626;
    outline: 1px solid #cfcfcf;
    :hover {
      cursor: pointer;
    }
  }
  input:focus,
  input:focus-within {
    border: none;
    box-shadow: none;
    &.green {
      outline: 1px solid green;
    }
    outline: 1px solid #262626;
  }
`;

const Container = styled.div`
  padding: 24px 18px;
  background: #fafafa;
  border-left: 1px solid #b9b9b9;
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
  :hover {
    cursor: pointer;
  }
  input {
    margin-right: 10px;
    &.green {
      color: green;
    }
    color: #262626;
    &.green {
      border: 1px solid green;
    }
    border: 1px solid #262626;
    :hover {
      cursor: pointer;
    }
  }
  input:focus {
    border: none;
    box-shadow: none;
  }
  input:focus-within {
    outline: none;
  }
  input:focus,
  input:focus-within {
    &.green {
      outline: 1px solid green;
    }
  }
`;

const LabelText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 4px;
`;

export default Payment;
