import styled from 'styled-components';

export const Subtitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: gray;
  margin-bottom: 22px;
`;
export const Description = styled.div`
  ul {
    list-style: none;
    display: inline;
  }
  li {
    display: inline;
  }
`;
