import { useApi, useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type Login = {
  error: string;
  token: string;
  user_display_name: string;
  user_email: string;
  user_nicename: string;
};

type LoginError = {
  error: string;
};

type LoginParams = {
  username: string;
  password: string;
};

type LoginResponse = Login | LoginError;

export const useLogin = (): UseMutationResult<
  LoginResponse,
  unknown,
  LoginParams
> => {
  const api = useApi();
  const unauthenticatedApi = useUnauthenticatedApi();
  const apiUrl = process.env.API_URL;

  const tokenLogin = async (payload: LoginParams): Promise<LoginResponse> => {
    try {
      return (
        await unauthenticatedApi.post('/wp-json/jwt-auth/v1/token', payload)
      ).data;
    } catch (error: any) {
      const errorResponse = {
        error: '',
      };
      const code = error.response.data.code;
      switch (code) {
        case '[jwt_auth] invalid_username':
        case '[jwt_auth] incorrect_password':
          errorResponse.error = 'Your email address or password is invalid.';
          break;
        default:
          errorResponse.error =
            'An unknown error has occurred during login, please contact support.';
          break;
      }
      return errorResponse;
    }
  };

  return useMutation(async (payload: LoginParams) => tokenLogin(payload), {
    onSuccess: (res): any => {
      const response = {
        error: '',
      };

      if (res.error) {
        response.error = res.error;
        //localStorage.setItem('token', 'hello111');
        window.location.href = '/dashboard/';
        return response;
      } else {
        if ('token' in res && res.token) {
          // todo: error message to notify us when this happens
          localStorage.setItem('token', JSON.stringify(res.token));
          api.post('/api/v1/login.php', {}).then(() => {
            window.location.href = '/dashboard/';
          });
        }
      }
    },
  });
};
