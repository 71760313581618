import React from 'react';
import styled from 'styled-components';

function LoginHeader(): JSX.Element {
  const apiUrl = process.env.API_URL;
  return (
    <LoginHeaderContainer>
      <SignIn>Sign In</SignIn>
      <SignUpContainer>
        <SignUp href={apiUrl}>Sign Up</SignUp>
      </SignUpContainer>
    </LoginHeaderContainer>
  );
}

const LoginHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SignIn = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const SignUp = styled.a`
  color: rgb(39, 174, 96);
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
`;

const SignUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
`;

export default LoginHeader;
