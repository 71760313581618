import { TrashIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'Utils/formatMoney';
import {
  defaultImage,
  PRODUCT_TYPE,
} from '../../../shared/helpers/subscription.helpers';
import 'typeface-poppins';
import 'typeface-roboto';

type Product = {
  id: string | number;
  image: string;
  short_description?: string;
  slug?: string;
  sku?: string;
  price: string | number;
  [key: string]: any;
};

type ItemInfo = {
  isReadOnly: boolean;
  removeItem?: (id: string | number) => void;
  product: Product;
};

const CartItem: FC<ItemInfo> = ({
  isReadOnly = false,
  removeItem = (): void => void 0,
  product,
}) => {
  const { id, image, title, slug, sku, price, short_description, type } =
    product;

  return (
    <div
      className={
        'bg-white relative flex p-[8px] gap-[16px] border-[1px] border-[#E4E4E4] radius-[9px] rounded min-h-[94px]'
      }
    >
      <div className='self-center'>
        <Image alt='Course image' src={image || defaultImage} />
      </div>
      <div className='self-center'>
        <Title>{title}</Title>{' '}
        {(type === PRODUCT_TYPE.variableSubscription ||
          type === PRODUCT_TYPE.variableProduct) && (
          <div
            className='text-gray-400'
            style={{ position: 'relative', top: '-15px' }}
          >
            [{slug}]
          </div>
        )}
        <span style={{ color: '#25ad61', fontWeight: 'bold' }}>
          {formatMoney(price)}
        </span>{' '}
        <Description
          dangerouslySetInnerHTML={{
            __html: `
                ${short_description || title}
                `,
          }}
        />
      </div>
      {!isReadOnly && (
        <div className='self-start absolute right-[6px]'>
          <TrashIcon
            onClick={(e): void => {
              e.preventDefault();
              removeItem(id);
            }}
            className='h-6 w-6 cursor-pointer text-red-300'
          />
        </div>
      )}
    </div>
  );
};

const Image = styled.img`
  max-width: 78px;
  max-height: 78px;
  border-radius: 4px;
`;

const Title = styled.div`
  font-family: 'Poppins', inherit, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  leading-trim: both;
  text-edge: cap;
  color: #000000;
  margin-bottom: 12px;
`;

const Description = styled.div`
  font-family: 'Poppins', inherit, sans-serif;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  leading-trim: both;
  text-edge: cap;
  display: inline;
  ul {
    list-style: initial;
    display: inline;
    li {
      display: inline;
    }
  }
`;

export default CartItem;
