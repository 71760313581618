import { CheckIcon } from '@heroicons/react/24/outline';
import Button from 'Components/common/button/Button';
import ComplexSelect, {
  DropdownSelectOption,
} from 'Components/common/complex-select/ComplexSelect';
import DotLoader from 'Components/common/dot-loader/DotLoader';
import Tooltip from 'Components/common/tooltip/Tooltip';
import { useCartContext } from 'Contexts/CartContext';
import { UserContext } from 'Contexts/UserContext';
import { useGetProducts } from 'Hooks/useGetProducts';
import React, { memo, useContext, useEffect, useState } from 'react';
import { formatMoney } from 'Utils/formatMoney';
import { classNames } from 'Utils/TailwindHelpers';
import {
  defaultImage,
  PRODUCT_TYPE,
} from '../../../shared/helpers/subscription.helpers';
import { Description, Subtitle } from './styled.general';
import 'typeface-poppins';
import 'typeface-roboto';
function SimpleProducts(): JSX.Element {
  const { addItem, productItems } = useCartContext();

  const { productsList, isFetching: isProductsFetching } = useGetProducts();

  const [selectVariant, setSelectVariant] = useState<string>('');
  const [selectedVariants, setSelectedVariants] = useState<any>([]);

  const isProductInBasket = (id: number | string): boolean => {
    return !!productItems.find((item: any) => item.id === Number(id));
  };

  const clearVariationsSelect = (): void => {
    setSelectVariant('NO');
    setSelectedVariants([]);
  };

  useEffect(() => {
    if (
      !productItems?.length ||
      !productItems.find(
        (item: any) =>
          item.type === PRODUCT_TYPE.variableProduct ||
          item.type === PRODUCT_TYPE.simpleProduct
      )
    ) {
      clearVariationsSelect();
    }
  }, []);

  const { fullUser: fullUserInfo } = useContext(UserContext);

  const isSomeProductBought = (product: string | number): boolean => {
    const currentItem = getSelectedVariantOfProduct(product)?.id;
    if (!fullUserInfo?.products) return false;
    return !!fullUserInfo.products.find(
      (item: string | number) => item === +currentItem
    );
  };

  function addProductHandler(item: any): void {
    if (selectVariant && item.type === PRODUCT_TYPE.variableProduct) {
      addItem({
        id: item?.id,
        variationId: selectVariant,
        type: item?.type,
      });
    } else {
      addItem({ id: item?.id, variationId: null, type: item?.type });
    }
    if (item.type === PRODUCT_TYPE.simpleProduct) {
      clearVariationsSelect();
    }
  }

  // universal setter for dropdown ui element onChange
  function variantSelected(variantSelection: DropdownSelectOption): void {
    if (variantSelection?.id) {
      setSelectVariant(variantSelection.id);
    }
  }

  function getCartVariantOfProduct(item: any): any {
    let res;
    outer: for (const item1 of productItems) {
      for (const item2 of item.variations) {
        if (Number(item1.variationId) === +item2.id) {
          res = item2;
          break outer;
        }
      }
    }
    return res || {};
  }

  function getSelectedVariantOfProduct(item: any): any {
    if (!item?.variations) return item;

    // first of all find in selected dropdown items
    for (const item1 of selectedVariants) {
      for (const variation of item.variations) {
        if (Number(item1.id) === Number(variation.id)) {
          return variation;
        }
      }
    }
    // after try to find in shopping cart product
    for (const item1 of productItems) {
      for (const variation of item.variations) {
        if (Number(item1.variationId) === Number(variation.id)) {
          return variation;
        }
      }
    }
    return item;
  }

  // unique side logic change dropdown value callback
  function selectCallback(name: string, selectedValue: string): void {
    let updatedVariants = selectedVariants?.length ? [...selectedVariants] : [];
    // for selections before add to cart remove last select value from an selections array
    updatedVariants = updatedVariants.filter((item: any) => item.name !== name);
    // set new value to the selected set from all subscription dropdowns array
    updatedVariants.push({ name, id: selectedValue });
    setSelectedVariants(updatedVariants);
  }

  const getDescription = (product: any): string => {
    const {
      short_description: productShortDescription,
      description: productFullDescription,
    } = product;

    const {
      short_description: variationShortDescription,
      description: variationFullDescription,
    } = getSelectedVariantOfProduct(product);

    return (
      variationShortDescription ||
      variationFullDescription ||
      productShortDescription ||
      productFullDescription ||
      ''
    );
  };

  return (
    <>
      <Subtitle className='subtitle'>Products</Subtitle>

      <div className='flex flex-1 justify-center items-center'>
        {isProductsFetching && <DotLoader />}
      </div>

      <ul className='grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'>
        {!isProductsFetching && productsList?.length
          ? productsList?.map((product: any) => {
              return (
                <div key={product?.id}>
                  <li className='rounded shadow-lg'>
                    <div className='group aspect-w-16 aspect-h-9 block w-full overflow-hidden'>
                      <img
                        src={product?.image || defaultImage}
                        alt='Product'
                        className='pointer-events-none object-cover group-hover:opacity-75'
                      />
                      <a
                        type='button'
                        className='absolute inset-0 focus:outline-none'
                      >
                        <span className='sr-only'>
                          View details for {product?.title}
                        </span>
                      </a>
                    </div>
                    <div className='py-[16px] px-[20px]'>
                      <a
                        className='no-underline visited:no-underline'
                        href={product?.permalink}
                      >
                        <div className='text-course-title text-base text-center font-semibold text-ellipsis pb-[16px]'>
                          {product?.title}
                        </div>
                        <div className='pb-[8px] text-sm text-center'>
                          {formatMoney(
                            product?.variations?.length
                              ? getSelectedVariantOfProduct(product)?.price
                              : product?.price
                          )}
                        </div>
                        <div className='pb-[8px] text-sm'>
                          <Description
                            dangerouslySetInnerHTML={{
                              __html: getDescription(product),
                            }}
                          />
                        </div>
                      </a>

                      {product?.type === 'variable' &&
                      product.variations?.length ? (
                        <div className='mt-[5px]'>
                          <ComplexSelect
                            name={`select_${product.id}`}
                            label='Select variant:'
                            onChange={variantSelected}
                            callback={selectCallback}
                            disabled={isProductInBasket(product?.id)}
                            selectedValue={
                              selectedVariants.find(
                                (item: any) =>
                                  item?.name === `select_${item.id}`
                              )?.id || getCartVariantOfProduct(product)?.id
                            }
                            options={product?.variations?.map(
                              (variant: {
                                id: string;
                                title: string;
                                slug: string;
                              }) => ({
                                id: variant.id,
                                name: variant.title,
                              })
                            )}
                          />
                        </div>
                      ) : product.variations ? (
                        <div className='pt-[8px] text-sm text-gray-500'>
                          No variations
                        </div>
                      ) : null}

                      <div className='group relative flex justify-center'>
                        {isSomeProductBought(product) && (
                          <Tooltip
                            simple
                            warning
                            message='You have already bought this product.'
                          />
                        )}
                        <div
                          className={classNames(
                            'w-full',
                            isProductInBasket(product?.id)
                              ? 'cursor-default'
                              : 'cursor-pointer'
                          )}
                        >
                          <Button
                            size='large'
                            borderColor='green-700'
                            width='w-full'
                            backgroundColor={
                              isProductInBasket(product?.id)
                                ? 'bg-neutral-200'
                                : product.variations &&
                                  !product.variations.length
                                ? 'bg-transparent'
                                : 'bg-white'
                            }
                            textColor={
                              product.variations && !product.variations.length
                                ? 'text-gray-300'
                                : 'text-green-700'
                            }
                            isDisabled={
                              isSomeProductBought(product) ||
                              isProductInBasket(product?.id) ||
                              (product.variations &&
                                !product.variations.length) ||
                              (product.type === PRODUCT_TYPE.variableProduct &&
                                getSelectedVariantOfProduct(product)?.id ===
                                  product?.id)
                            }
                            onClick={(): void => addProductHandler(product)}
                          >
                            {!isProductInBasket(product?.id) ? (
                              'Add to cart'
                            ) : (
                              <>
                                <CheckIcon
                                  className='block h-6 w-6 mr-1'
                                  aria-hidden='true'
                                />
                                In basket
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })
          : null}
      </ul>
    </>
  );
}

export default memo(SimpleProducts);
