import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import 'typeface-poppins';
import styled from 'styled-components';
import { classNames } from 'Utils/TailwindHelpers';

export type DropdownSelectOption = {
  id: string;
  name: string;
};

type DropdownSelectProps = {
  onChange?: (selectedItem: DropdownSelectOption) => void;
  callback?: (name: string, selectedValue: string) => void;
  name?: string;
  options: DropdownSelectOption[];
  label?: string;
  disabled?: boolean;
  selectedValue?: string;
  required?: boolean | undefined;
  isError?: boolean;
  errorText?: string;
};

function ComplexSelect({
  onChange,
  options,
  label,
  disabled = false,
  selectedValue,
  required = false,
  callback,
  name,
  isError = false,
  errorText = '',
}: DropdownSelectProps): JSX.Element {
  const placeholderItem = useMemo(() => {
    return { id: 'NO', name: 'Choose an option' };
  }, []);
  const selectBtnRef = useRef<HTMLButtonElement>(null);

  const defaultItem = selectedValue
    ? {
        id: selectedValue,
        name: options.find((item) => +selectedValue === +item.id)?.name || '',
      }
    : placeholderItem;
  const [selected, setSelected] = useState<DropdownSelectOption>(defaultItem);

  useEffect(() => {
    if (!selectedValue) {
      setSelected(placeholderItem);
    }
  }, [placeholderItem, selectedValue]);
  function handleChange(selectedItem: DropdownSelectOption): void {
    if (disabled) return;

    setSelected(selectedItem);
    if (onChange) {
      onChange(selectedItem);
    }
    if (callback && name) {
      callback(name, selectedItem.id);
    }
  }

  return (
    <>
      <Listbox value={selected} onChange={handleChange}>
        {({ open }): JSX.Element => (
          <>
            {label && (
              <LabelText>
                {label} {required && '*'}
              </LabelText>
            )}
            <div className='relative mt-1'>
              <Listbox.Button
                onFocus={(e): void => {
                  e.preventDefault();
                  if (e.target && selectBtnRef.current && disabled) {
                    selectBtnRef.current.blur();
                  }
                }}
                ref={selectBtnRef}
                className={classNames(
                  isError ? 'border-red-500' : 'border-gray-300',
                  'py-[9px] px-[13px] relative w-full cursor-default rounded-md border bg-white text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm',
                  disabled ? 'bg-gray-100' : ' bg-white'
                )}
              >
                <span className='flex items-center'>
                  <span
                    className={`${
                      selected.id === defaultItem.id
                        ? 'text-gray-500'
                        : 'text-initial'
                    } block truncate`}
                  >
                    {selected?.name}
                  </span>
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                  <ChevronUpDownIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              {!disabled && (
                <Transition
                  show={open}
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='min-height-[40px] absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                    {options.map((option) => (
                      <Listbox.Option
                        key={option?.id}
                        className={({ active }): string =>
                          classNames(
                            active
                              ? 'text-white bg-primary-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-[9px] px-[13px]'
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }): JSX.Element => (
                          <>
                            <div className='flex items-center'>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'ml-3 block truncate'
                                )}
                              >
                                {option?.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-500',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              )}
            </div>
          </>
        )}
      </Listbox>
      {isError && <ErrorText className='text-red-500'>{errorText}</ErrorText>}
    </>
  );
}

const LabelText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #707070;
`;
const ErrorText = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-top: 2px;
`;

export default ComplexSelect;
