import { useApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type CourseEnrollParams = {
  courseId: number;
};

type CourseEnrollResponse = {
  success: boolean;
  error: string;
};

export const useSetEnrollInCourse = (): UseMutationResult<
  CourseEnrollResponse,
  unknown,
  CourseEnrollParams
> => {
  const api = useApi();

  const setEnrollInCourse = async (
    payload: CourseEnrollParams
  ): Promise<CourseEnrollResponse> => {
    return (await api.post('/api/v1/course-enroll.php', payload)).data;
  };

  return useMutation(async (payload: CourseEnrollParams) =>
    setEnrollInCourse(payload)
  );
};
