import Button from 'Components/common/button/Button';
import Input from 'Components/common/input/Input';
import Link from 'Components/common/link/Link';
import Message from 'Components/common/message/Message';
import PrivacyPolicy from 'Components/features/privacy-policy/PrivacyPolicy';
import { useResetPassword } from 'Hooks/useResetPassword';
import React from 'react';
import { useRef, useState } from 'react';
import styled from 'styled-components';

function ForgotPassword(): JSX.Element {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { mutateAsync: resetPassword } = useResetPassword();
  const submitForgotPassword = async (): Promise<void> => {
    setErrorMessage('');
    const email =
      emailRef.current instanceof HTMLInputElement
        ? emailRef.current.value
        : '';
    if (email === '') {
      setErrorMessage('Please enter an email address.');
      return;
    } else {
      const resp = await resetPassword({ user_email: email });
      if (resp?.error) {
        setErrorMessage(resp.error);
      } else {
        window.location.href = '/login?checkemail=confirm';
      }
    }
  };
  return (
    <>
      <HeaderText>Forgot your password?</HeaderText>
      <Message>
        Please enter your email address. You will receive an email with
        instructions on how to reset your password.
      </Message>
      {errorMessage && (
        <Message color='#EF3E46'>
          Error: There is no account with that username or email address.
        </Message>
      )}
      <InputContainer>
        <Input
          id='email'
          name='email'
          placeholder='Email Address'
          inputRef={emailRef}
        />
      </InputContainer>
      <Button size='small' onClick={submitForgotPassword}>
        Request Reset Link
      </Button>
      <BackLinkContainer>
        <Link href='/login'>Back to sign in</Link>
      </BackLinkContainer>
      <PrivacyPolicy />
    </>
  );
}

const BackLinkContainer = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: rgb(77, 92, 109);
`;

const InputContainer = styled.div`
  padding-top: 15px;
`;

export default ForgotPassword;
