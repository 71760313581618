import React from 'react';
import styled from 'styled-components';

type LoaderProps = {
  type?: string;
};

function DotLoader({ type = 'big' }: LoaderProps): JSX.Element {
  return (
    <>
      {type === 'big' && (
        <Loader className='flex block relative h-[20px] w-[140px]'></Loader>
      )}
      {type === 'small' && (
        <LoaderSmall className='flex block relative h-[10px] w-[100px]'></LoaderSmall>
      )}
    </>
  );
}

const Loader = styled.div`
  background-image: linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: 0 0, 40px 0, 80px 0, 120px 0;
  animation: pgfill 1s linear infinite;

  @keyframes pgfill {
    0% {
      background-image: linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0);
    }
    25% {
      background-image: linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0);
    }
    50% {
      background-image: linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0);
    }
    75% {
      background-image: linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#fff 20px, transparent 0);
    }
    100% {
      background-image: linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0),
        linear-gradient(#27ae60 20px, transparent 0);
    }
  }
`;

const LoaderSmall = styled.div`
  background-image: linear-gradient(#fff 5px, transparent 0),
    linear-gradient(#fff 5px, transparent 0),
    linear-gradient(#fff 5px, transparent 0),
    linear-gradient(#fff 5px, transparent 0);
  background-repeat: no-repeat;
  background-size: 10px auto;
  background-position: 0 0, 30px 0, 60px 0, 90px 0;
  animation: pgfill_small 1s linear infinite;

  @keyframes pgfill_small {
    0% {
      background-image: linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0);
    }
    25% {
      background-image: linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0);
    }
    50% {
      background-image: linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0);
    }
    75% {
      background-image: linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#fff 5px, transparent 0);
    }
    100% {
      background-image: linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0),
        linear-gradient(#27ae60 5px, transparent 0);
    }
  }
`;

export default DotLoader;
