import React from 'react';

type InputParams = {
  id: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUpKey?: string;
  placeholder?: string;
  type?: string;

  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  autoComplete?: string;
};

const Input = ({
  id,
  inputRef,
  name,
  onKeyUp = (): void => void 0,
  onKeyUpKey = 'Enter',
  placeholder,
  type = 'text',
  value,
  onChange,
  required,
  autoComplete,
}: InputParams): JSX.Element => {
  function executeKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (onKeyUp) {
      if (event.key === onKeyUpKey) {
        onKeyUp(event);
      }
    }
  }
  return (
    <div className='col-span-6 sm:col-span-3'>
      <input
        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm'
        id={id}
        name={name}
        onKeyUp={executeKeyUp}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default Input;
