import Footer from 'Components/ui/footer/Footer';
import MainHeader from 'Components/ui/header/MainHeader';
import React, { ReactNode } from 'react';

type LayoutProps = {
  children: React.ReactNode;
  background?: string;
  prefooter?: ReactNode;
};

function Layout({ children, background, prefooter }: LayoutProps): JSX.Element {
  return (
    <div className='min-h-screen flex flex-col'>
      <MainHeader />
      <div
        className='flex-grow'
        style={background ? { background: background } : {}}
      >
        {children}
      </div>
      {prefooter}
      <Footer />
    </div>
  );
}

export default Layout;
