import { UserContext } from 'Contexts/UserContext';
import { useGetProductsByStorage } from 'Hooks/useGetProducts';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PRODUCT_TYPE } from '../helpers/subscription.helpers';

export type StorageProduct = {
  id: number;
  type: string;
  variationId: number | null;
};
export type DetailedProduct = {
  description: string;
  gallery?: any[];
  id: number;
  image: string;
  interval?: number;
  length: number;
  needShipping?: boolean;
  period: string;
  price: string | number;
  regularPrice: string | number;
  salePrice: string | number;
  shortDescription: string;
  signUpFee?: number;
  sku: string;
  slug: string;
  title: string;
  trialLength: number;
  trialPeriod: string;
  type: string;
  [key: string]: any;
};

type CartContextData = {
  productItems: StorageProduct[];
  setProductItems: (value: StorageProduct[]) => void;
  detailedItems: DetailedProduct[];
  setDetailedItems: (value: DetailedProduct[]) => void;
  isProductInBasket: (id: number) => boolean;
  getSubscriptionFromCart: () => StorageProduct | undefined;
  getCartList: () => any;
  customerEmail: string;
  addItem: ({
    id,
    variationId,
    type,
  }: {
    id: number;
    variationId: number | null | any;
    type: string | any;
  }) => void;
  removeItem: (value: number | string) => void;
  clearAll: () => void;
  getPurchaseAmount: () => number;
};

type UserProviderProps = {
  children: JSX.Element;
};

export const CartContext = createContext<CartContextData>({
  productItems: [],
  setProductItems: () => void 0,
  detailedItems: [],
  setDetailedItems: () => void 0,
  isProductInBasket: () => false,
  getSubscriptionFromCart: () => void 0,
  getCartList: () => [],
  customerEmail: '',
  addItem: () => void 0,
  removeItem: () => void 0,
  clearAll: () => void 0,
  getPurchaseAmount: () => 0,
});

export function useCartContext(): CartContextData {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('Cart Context is not included');
  }
  return context;
}

type ProductsQuery = [DetailedProduct[], boolean];

export function CartProvider({ children }: UserProviderProps): JSX.Element {
  const { user } = useContext(UserContext);
  const customerEmail = user?.email || '';

  const [productItems, setProductItems] = useState<StorageProduct[]>([]);
  const [detailedItems, setDetailedItems] = useState<DetailedProduct[]>([]);

  const [items, isFetching]: ProductsQuery = useGetProductsByStorage(
    productItems.map((item) => {
      const id = item?.id;
      const variationId = item?.variationId;
      const type = item?.type;
      return { id, variationId, type };
    })
  );

  const isProductInBasket = (id: number): boolean => {
    id = Number(id);
    return !!productItems.find((item: StorageProduct) => item.id === id);
  };

  const getPurchaseAmount = () => {
    let amount = 0;
    if (detailedItems?.length) {
      amount =
        detailedItems.reduce((acc: number, val: DetailedProduct) => {
          acc += Number(val?.price) || 0;
          return acc;
        }, 0) || 0;
    }
    return amount;
  };

  const getCartList = (): DetailedProduct[] => {
    return detailedItems;
  };

  useEffect(() => {
    if (items?.length && Number(items?.[0]?.id) > 0) {
      const currentItems: DetailedProduct[] = items.map((product) => {
        if (
          product?.type === PRODUCT_TYPE.variableSubscription ||
          product?.type === PRODUCT_TYPE.variableProduct
        ) {
          for (const productItem of productItems) {
            const currentEl = { ...product }.variations.find(
              (item: DetailedProduct) =>
                Number(item.id) === Number(productItem.variationId)
            );
            if (currentEl) {
              return {
                ...currentEl,
                short_description:
                  currentEl?.short_description || product?.short_description,
              };
            }
          }
        }
        return product;
      });
      setDetailedItems(currentItems);
    }
  }, [isFetching, productItems]);

  useEffect(() => {
    const arr: string | null = localStorage.getItem('products');
    if (arr) {
      const products: StorageProduct[] = JSON.parse(arr);
      setProductItems([...products]);
    }
  }, [localStorage]);

  const getSubscriptionFromCart = (): StorageProduct | undefined => {
    return productItems.find(
      (item: StorageProduct) =>
        item?.type === PRODUCT_TYPE.subscription ||
        item?.type === PRODUCT_TYPE.variableSubscription
    );
  };

  function addItem({
    id,
    variationId,
    type,
  }: {
    id: number;
    variationId: number | null;
    type: string;
  }): void {
    id = Number(id);
    if (productItems.find((item: StorageProduct) => item?.id === id)) {
      return;
    }

    const updatedItems: StorageProduct[] = [...productItems];
    updatedItems.push({
      type,
      id,
      variationId: variationId || null,
    });
    setProductItems(updatedItems);
    localStorage.setItem('products', JSON.stringify(updatedItems));
  }

  function removeItem(id: number | string): void {
    let updatedItems: StorageProduct[] = [];
    let updatedDetailedItems: DetailedProduct[] = [];

    if (productItems.find((item) => +item.id === id)) {
      updatedDetailedItems = [...detailedItems].filter(
        (el: DetailedProduct) => +el.id !== +id
      );
      setDetailedItems(updatedDetailedItems);

      updatedItems =
        [...productItems].filter((el: StorageProduct) => +el.id !== +id) || [];
      setProductItems(updatedItems);
      localStorage.setItem('products', JSON.stringify(updatedItems));
      return;
    }

    const cartItem: StorageProduct | undefined = productItems.find(
      (item: StorageProduct) => Number(item.variationId) === Number(id)
    );

    if (cartItem) {
      updatedDetailedItems = [...detailedItems].filter(
        (el: DetailedProduct) => Number(el.id) !== Number(cartItem.variationId)
      );
      setDetailedItems(updatedDetailedItems);
      updatedItems = [...productItems].filter(
        (el: StorageProduct) => +el.id !== cartItem.id
      );
      setProductItems(updatedItems);
      localStorage.setItem('products', JSON.stringify(updatedItems));
    }
  }

  function clearAll(): void {
    setProductItems([]);
    setDetailedItems([]);
    localStorage.removeItem('products');
  }

  return (
    <CartContext.Provider
      value={{
        productItems,
        setProductItems,
        detailedItems,
        setDetailedItems,
        isProductInBasket,
        getSubscriptionFromCart,
        getCartList,
        customerEmail,
        addItem,
        removeItem,
        clearAll,
        getPurchaseAmount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
