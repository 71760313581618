import { useUnauthenticatedApi } from 'Contexts/ApiContext';
import { useMutation, UseMutationResult } from 'react-query';

type PasswordResetParams = {
  pass1: string;
  pass2: string;
  rp_key: string;
  rp_login: string;
};

type PasswordResetResponse = {
  success: boolean;
  error: string;
};

export const useSetNewPassword = (): UseMutationResult<
  PasswordResetResponse,
  unknown,
  PasswordResetParams
> => {
  const api = useUnauthenticatedApi();

  const resetPassword = async (
    payload: PasswordResetParams
  ): Promise<PasswordResetResponse> =>
    (await api.post('/api/v1/set-new-password.php', payload)).data;

  return useMutation(async (payload: PasswordResetParams) =>
    resetPassword(payload)
  );
};
