import {
  CheckCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/solid';
import { CourseDetail, LessonDetail } from 'Hooks/useGetCourseDetail';
import { LessonActivity, UserCourseDetail } from 'Hooks/useGetUserCourseDetail';
import React from 'react';

type LessonBarProps = {
  courseDetail: CourseDetail;
  isLoggedIn: boolean;
  lessonDetail: LessonDetail;
  userCourseDetail: UserCourseDetail;
};

const LessonBar: React.FC<LessonBarProps> = ({
  courseDetail,
  isLoggedIn,
  lessonDetail,
  userCourseDetail,
}) => {
  function isLessonComplete(lessonId: number): boolean {
    return userCourseDetail.lessonActivity?.some(
      (lessonActivity: LessonActivity) =>
        lessonActivity.lessonId === lessonId && lessonActivity.completed === '1'
    );
  }
  function isSampleLesson(
    lessonId: number,
    lessonDetails: LessonDetail[]
  ): boolean {
    return lessonDetails.length > 0 && lessonDetails[0].id === lessonId;
  }

  return (
    <div
      key={lessonDetail.id}
      className={`flex flex-row items-center justify-between bg-white border-2 cursor-pointer box-border p-[20px] rounded-md my-4 group relative ${
        isLessonComplete(lessonDetail.id) ? 'line-through' : ''
      }`}
    >
      {!isLoggedIn &&
        !isSampleLesson(lessonDetail.id, courseDetail?.lessonDetails) && (
          <span className='absolute top-[-25px] right-[40px] scale-0 rounded bg-primary-500 p-2 text-xs text-black group-hover:scale-100 transition-all duration-300'>
            You don&apos;t currently have access to this content
          </span>
        )}
      <div className='font-medium text-lg hover:text-primary-500 flex flex-row items-center'>
        <a
          className='no-underline visited:no-underline cursor-pointer'
          href={
            isLoggedIn ||
            isSampleLesson(lessonDetail.id, courseDetail?.lessonDetails)
              ? lessonDetail.lessonUrl
              : ''
          }
        >
          {lessonDetail.lessonTitle}
        </a>
        {!isLoggedIn &&
          !isSampleLesson(lessonDetail.id, courseDetail?.lessonDetails) && (
            <LockClosedIcon
              className='h-[16px] w-[16px] min-w-[16px] mt-[4px] ml-[8px] mr-[8px]'
              aria-hidden='true'
            />
          )}
      </div>
      {!isLoggedIn &&
        isSampleLesson(lessonDetail.id, courseDetail?.lessonDetails) && (
          <div className='flex-row flex px-[25px] rounded-full bg-primary-500 text-black text-center p-[5px] text-[11px] font-semibold items-center mr-[8px]'>
            <LockOpenIcon
              className='h-[16px] w-[16px] min-w-[16px] mr-[5px]'
              aria-hidden='true'
            />{' '}
            Sample Lesson
          </div>
        )}
      {isLessonComplete(lessonDetail.id) ? (
        <CheckCircleIcon
          className='h-[32px] w-[32px] min-w-[32px] mr-[6px]'
          aria-hidden='true'
        />
      ) : (
        <div className='border-4 border-solid rounded-full border-[#e2e7ed] h-[32px] w-[32px] min-w-[32px] mr-[6px]' />
      )}
    </div>
  );
};

export default LessonBar;
