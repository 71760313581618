import { useApi } from 'Contexts/ApiContext';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type User = {
  email: string;
  favoriteCourses: number[];
  first_name: string;
  user_login: string;
  user_id?: string;
};

type UserResponse = {
  userInfo: User | undefined;
  isFetching: boolean;
};

type FullUserResponse = {
  fullUserInfo: any | undefined;
  isFetching: boolean;
};

export const useGetUserInfo = (isLoggedIn: boolean): UserResponse => {
  const api = useApi();
  const fetchUserInfo = async (): Promise<User> =>
    (await api.get('/api/v1/user.php')).data;
  const placeholderData = useMemo(() => {
    return {
      favoriteCourses: [],
      user_id: '',
      user_login: '',
      email: '',
      first_name: '',
    };
  }, []);
  const { data: userInfo, isFetching } = useQuery(['userInfo'], fetchUserInfo, {
    enabled: isLoggedIn,
    onError: (error) => console.error(error),
    placeholderData,
  });
  return { userInfo, isFetching };
};

export const useGetFullUserInfo = (isLoggedIn: boolean): FullUserResponse => {
  const api = useApi();
  const fetchUserInfo = async (): Promise<any> =>
    (
      await api.get(
        '/wp-json/smart-piano-api/v1/user?orders=true&products=true&subscriptions=true&memberships=true'
      )
    ).data;
  const placeholderData = useMemo(() => {
    return {
      // favoriteCourses: [],
      // user_id: '',
      // user_login: '',
      // email: '',
      // first_name: '',
    };
  }, []);
  const { data: fullUserInfo, isFetching } = useQuery(
    ['fullUserInfo'],
    fetchUserInfo,
    {
      enabled: isLoggedIn,
      onError: (error) => console.error(error),
      placeholderData,
    }
  );
  return { fullUserInfo: fullUserInfo?.data, isFetching };
};
